import { useContext, useState } from 'react'
import { UserContext } from '../../../context/UserContext'
import ModalConfirmChangeSubscription from '../../ModalConfirmChangeSubscription/ModalConfirmChangeSubscription'
import axios from 'axios'
import { toast } from 'react-toastify'
import { Button, CircularProgress } from '@mui/material'

const options = {
  title: 'Actualizar suscripción',
  text: '¿Estás seguro que quieres actualizar tu suscripción? Si cuenta con alguna suscripción previa, esta se cancelará y se actualizará a la nueva suscripción.',
}

const SubscribeButtonIfAlreadyExistsSubscription = ({ price }) => {
  const [isModalOpened, setIsModalOpened] = useState(false)
  const action = `/api/v1/stripe/session?price_id=${price.id}&redirect=false`

  return (
    <>
      <ModalConfirmChangeSubscription
        show={isModalOpened}
        onHide={() => setIsModalOpened(false)}
        title={options.title}
        text={options.text}
        textButton="Actualizar"
        action={action}
      />
      <Button
        type="submit"
        style={{
          width: '100%',
          marginTop: '2rem',
          // fontSize: '1.4rem',
          fontWeight: '600',
        }}
        onClick={() => setIsModalOpened(true)}
      >
        Suscribirse
      </Button>
    </>
  )
}

const SubscribeButton = ({ price }) => {
  const [loadingRedirect, setLoadingRedirect] = useState(false)
  const action = `/api/v1/stripe/session?price_id=${price.id}&redirect=false`

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      const { data } = await axios.post(action)
      if (data?.data?.url) {
        window.location.href = data?.data?.url
      }
    } catch (e) {
      setLoadingRedirect(false)
      if (e?.response?.data?.show)
        return toast.error(e?.response?.data?.message)
    }
  }

  return (
    <form method="POST" onSubmit={onSubmit}>
      <div>
        <Button
          onClick={() => setLoadingRedirect(true)}
          endIcon={
            loadingRedirect && (
              <CircularProgress
                style={{
                  color: 'white',
                }}
                size={20}
              />
            )
          }
          variant="contained"
          type="submit"
          style={{
            width: '100%',
            textTransform: 'none',
            marginTop: '2rem',
            fontSize: '12px',
            fontWeight: '400',
            backgroundColor: '#2891c2',
          }}
        >
          Suscribirse
        </Button>
      </div>
    </form>
  )
}

export default SubscribeButton
