import React, { useContext, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import { NavLink } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar'
import LogoutButton from '../../auth0-config/logout'
import { useAuth0 } from '@auth0/auth0-react'
import LoginButton from '../../auth0-config/login'

import AuthorizeButton from '../ModalAuthorize/AuthorizeButton'
import { UserContext } from '../../context/UserContext'
import DropdownUserLogin from './DropdownUserLogin'
import { Button } from 'react-bootstrap'

const NavbarLat = () => {
  const { user, isAuthenticated, getAccessTokenSilently, getIdTokenClaims } =
    useAuth0()
  const data = useContext(UserContext)

  // const getAccessToken = async () => {
  //   const access_token = await getAccessTokenSilently()
  //   const claims = await getIdTokenClaims()
  //   console.log('accessToken', access_token)
  // }

  return (
    <>
      <Navbar bg="light" expand="lg" className="sticky-top">
        <div
          style={{
            padding: '0 5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100vw ',
          }}
        >
          {/* TITULO Y LOGO */}
          <NavLink to="/" style={{ textDecoration: 'none' }}>
            <Navbar.Brand className="header-brand" to="/">
              <div className="header-logo-container">
                <img src="https://bimsualize.fra1.digitaloceanspaces.com/logo_bimsualize.png" />
              </div>
              <p className="header-brand__bim">BIM</p>
              <p className="header-brand__visualice">sualize</p>
            </Navbar.Brand>
          </NavLink>
          {/* OPCIONES DEL NAVBAR */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
            {/* <Nav.Link to="/">Home</Nav.Link> */}
            <NavLink
              style={{
                fontSize: '1.5rem',
                fontWeight: '600',
                fontFamily: 'Lato',
              }}
              to="/app"
            >
              App
            </NavLink>
            <NavLink
              style={{
                fontSize: '1.5rem',
                fontWeight: '600',
                fontFamily: 'Lato',
              }}
              to="/precios"
            >
              Precios
            </NavLink>
            <NavLink
              style={{
                fontSize: '1.5rem',
                fontWeight: '600',
                fontFamily: 'Lato',
              }}
              to="/recursos"
            >
              Recursos
            </NavLink>
            {/* <Button onClick={getAccessToken}>Prueba</Button> */}
            <NavLink
              style={{
                fontSize: '1.5rem',
                fontWeight: '600',
                fontFamily: 'Lato',
              }}
              to="/docs/category/primeros-pasos"
              target="_blank"
            >
              Docs
            </NavLink>

            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
            <NavDropdown.Item to="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item to="#action/3.2">
              Another action //{' '}
            </NavDropdown.Item>
          </NavDropdown> */}
          </div>
          {/* BOTON DE LOGIN/USUARIO Y FOTO DE PERFIL */}
          <div>
            {isAuthenticated ? (
              <div
                style={{
                  display: 'flex',
                  gap: '2rem',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <>
                  {data?.userAutodesk && (
                    <>
                      <AuthorizeButton />
                      <div
                        style={{
                          height: '30px',
                          width: '1px',
                          backgroundColor: '#c6c6c6',
                        }}
                      ></div>
                    </>
                  )}
                  <DropdownUserLogin trialEnd={data.endTrial} />
                  {/* <img
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '40px',
                    }}
                    src={user.picture}
                    referrerPolicy="no-referrer"
                    alt="user-profile"
                  />
                  <span>{user.email}</span> */}
                </>
                {/* <LogoutButton /> */}
              </div>
            ) : (
              <LoginButton />
            )}
          </div>
        </div>
      </Navbar>
    </>
  )
}

export default NavbarLat

{
  /* <Box sx={{ flexGrow: 1 }} className="header-app">
   
      <Navbar.Brand to="#home">Latam-viewer</Navbar.Brand>
   
      <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
        <NavLink to="/">Home</NavLink>
        <Nav.Link to="/latamviewer">Latamviewer</Nav.Link>
        <Nav.Link to="/precios">Precios</Nav.Link>
        <Nav.Link to="/recursos">Recursos</Nav.Link>
        <NavDropdown title="Dropdown" id="basic-nav-dropdown">
          <NavDropdown.Item to="#action/3.1">Action</NavDropdown.Item>
          <NavDropdown.Item to="#action/3.2">Another action</NavDropdown.Item>
        </NavDropdown>
      </div>
      <div>
        {isAuthenticated ? (
          <div style={{ display: 'flex', gap: '2rem', alignItems: 'center' }}>
            <>
              <img
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: '40px',
                }}
                src={user.picture}
                referrerPolicy="no-referrer"
              />
              <span>{user.email}</span>
            </>
            <LogoutButton />
          </div>
        ) : (
          <LoginButton />
        )}
      </div>
    </Box> */
}
