import React from 'react'
import { getFileExtensionColors } from '../../utils/handleFileExtension'
import { Badge } from '@mui/material'

const handleExtensionName = (extension) => {
    if (extension == 'nwc' || extension == 'nwd') {
        return 'nwc | nwd'
    }
    return extension
}


const BadgeFileExtension = ({extension}) => {
  return (
    <Badge
    style={{
      marginLeft: '1rem',
      backgroundColor:
        getFileExtensionColors(extension),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      padding: '0.4rem 0.7rem',
      fontSize: "11px",
    //   width: '80px',
    //   height: '20px',
      borderRadius: '4px',
    }}
  >
    {handleExtensionName(extension)}
  </Badge>
  )
}

export default BadgeFileExtension