import { createContext, useEffect, useState } from 'react'

export const ViewerContext = createContext(null)

export const ViewerProvider = ({ children }) => {
  const [areViewerDbIdsLoaded, setAreViewerDbIdsLoaded] = useState(false)
  const [allPropertyNames, setAllPropertyNames] = useState(false)
  const [selectedGuidViewCurrentModel, setSelectedGuidViewCurrentModel] =
    useState(null)

  const [relaunchViewer, setRelaunchViewer] = useState(0)

  const handleRelaunchViewer = () => {
    setRelaunchViewer((prev) => prev + 1)
  }

  useEffect(() => {
    console.log('relaunchViewer', relaunchViewer)
  }, [relaunchViewer])

  const data = {
    areViewerDbIdsLoaded,
    setAreViewerDbIdsLoaded,
    allPropertyNames,
    setAllPropertyNames,
    selectedGuidViewCurrentModel,
    setSelectedGuidViewCurrentModel,
    handleRelaunchViewer,
    relaunchViewer,
  }

  return (
    <ViewerContext.Provider value={data}>{children}</ViewerContext.Provider>
  )
}
