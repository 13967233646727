export const encodeBase64 = (string) => {
  return btoa(string).replace(/\//g, '_')
}

export const decodeBase64 = (string) => {
  // Reemplazar caracteres Base64 URL-safe con los estándar
  const base64 = string.replace(/-/g, '+').replace(/_/g, '/')
  try {
    const decode = atob(base64)

    return decode
  } catch (e) {
    console.log('e')
  }
}
