import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/esm/Button'

const ModalDeleteAuthorization = ({ show, onHide, handleDelete, name }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      animation={false}
      fade={false}
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title>Eliminar autorización</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ display: 'flex' }}>
          ¿Estás seguro que quieres eliminar{' '}
          <p style={{ fontWeight: '700', marginLeft: '.4rem' }}>{name}</p>?{' '}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant="secondary">
          Cancelar
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalDeleteAuthorization
