import axios from 'axios'
import React from 'react'
import ProductTitle from './components/ProductTitle'
import ProductPrice from './components/ProductPrice'
import ProductButton from './components/ProductButton'
import ProductFeatures from './components/ProductFeatures'

const ProductCard = ({ product, noHover }) => {
  console.log(product)
  return (
    <div className={`product-card ${noHover && 'no-hover'}`}>
      <div>
        <ProductTitle product={product} />
        <ProductPrice product={product} />
        <ProductFeatures product={product} />
      </div>
      <ProductButton product={product} />
    </div>
  )
}

export default ProductCard
