import React from 'react'

const optionsCurrency = {
  usd: {
    currency: '$',
    name: 'US',
  },
  eur: {
    currency: '€',
    name: '',
  },
}

const optionsPayment = {
  month: 'mes',
  year: 'año',
}
const ProductPrice = ({ product }) => {
  return (
    <div
      className="price-container"
      style={{
        marginTop: '1rem',
        display: 'flex',
        flexDirection: 'column',
        height: '30px',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p
          style={{
            fontSize: '24px',
            fontWeight: '400',
            lineHeight: '28px',
            color: 'black',
            marginBottom: '0',
          }}
        >{`${product.unit_amount / 100} ${
          optionsCurrency[product.currency]?.currency
        }`}</p>
        {product.id !== process.env.REACT_APP_STRIPE_PRICEID_TRIAL ? (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '28px',
              color: '#737373',
              marginLeft: '.3rem',
              color: 'rgba(0, 0, 0, 0.75)',
            }}
          >
            /{optionsPayment[product.recurring?.interval]}
          </span>
        ) : (
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '18px',
              fontWeight: '400',
              lineHeight: '28px',
              color: '#737373',
              marginLeft: '.3rem',
              color: 'rgba(0, 0, 0, 0.75)',
            }}
          >
            / 30 dias de prueba
          </span>
        )}
      </div>

      {/* TOTAL AHORRADO */}
      {product.recurring.interval == 'year' && product.unit_amount != 0 && (
        <p
          style={{
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '28px',
            color: '#3A6079',
          }}
        >
          Ahorra un 17%
        </p>
      )}
    </div>
  )
}

export default ProductPrice
