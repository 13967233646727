import React, { Suspense } from 'react'
import Container from 'react-bootstrap/Container'
import NavbarLat from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import LegalFooter from '../components/Footer/LegalFooter'
import ProductsContainer from '../components/Products/ProductsContainer'
import PricesCardsSkeleton from '../components/Spinner/Skeletons/PricesCardsSkeleton'
const Precios = () => {
  return (
    <div
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <NavbarLat />
      <Container
        fluid
        style={{
          padding: 0,
          background: 'rgb(240, 242, 245)',
        }}
      >
        <div
          className="recursos-image-container"
          style={{ backgroundImage: 'url(assets/img/b2.jpg)' }}
          // style={{ backgroundImage: 'url(assets/img/b3.jpg)' }}
        >
          <div
            className="recursos-image"
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <h1
              style={{ color: 'white', textAlign: 'center', marginTop: '7rem' }}
            >
              Precios
            </h1>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <h3
                style={{
                  color: 'white',
                  textAlign: 'center',
                  maxWidth: '50%',
                }}
              >
                Elige uno de los planes o prueba la versión gratuita durante 30
                días
              </h3>
            </div>
          </div>
        </div>
        {/* <h1 style={{ textAlign: 'center' }}>Planes y precios</h1>
        <p
          style={{
            textAlign: 'center',
            fontSize: '1.5rem',
            marginBottom: '3rem',
          }}
        >
          Elige uno de los planes o prueba la versión gratuita durante 30 días
        </p> */}

        {/* <stripe-pricing-table
          pricing-table-id="prctbl_1NAt2hJT0RLUPCLZiQGDq5ss"
          publishable-key="pk_test_CfKDVjTHANxgulOumBnNWbtp00ACJSmP9E"
          class="pricing-table"
        ></stripe-pricing-table> */}
        <div className="recursos-container">
          <Suspense fallback={<PricesCardsSkeleton />}>
            <ProductsContainer />
          </Suspense>
        </div>
      </Container>
      <Footer />
      <LegalFooter />
    </div>
  )
}

export default Precios
