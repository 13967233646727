import PaymentsIcon from '@mui/icons-material/Payments'
import NavbarItemSettingPage from './components/NavbarItemSettingPage'

const menu = [
  {
    id: 'Suscripciones',
    label: 'Suscripciones',
    href: '/subscription',
    icon: PaymentsIcon,
  },
]

const NavbarSettingsPage = () => {

  return (
    <nav className="sidebar-settings">
      {menu.map((item) => (
        <NavbarItemSettingPage key={item.id} item={item} />
      ))}
    </nav>
  )
}

export default NavbarSettingsPage
