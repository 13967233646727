import Navbar from './components/Navbar/Navbar'
import AppRouter, { router } from './routing/index'
import './App.css'
import { useAuth0 } from '@auth0/auth0-react'
import GlobalSpinner from './components/Common/GlobalSpinner'
import { UserProvider } from './context/UserContext'

function App() {
  const { isLoading } = useAuth0()
  return isLoading ? (
    <GlobalSpinner />
  ) : (
    <UserProvider>
      <AppRouter />
    </UserProvider>
  )
}

export default App
