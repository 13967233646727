export function getUniqueElements(arr1, arr2) {
  // Combina ambos arrays en uno solo
  var combinedArray = arr1.concat(arr2)

  // Crea un nuevo array para almacenar los elementos únicos
  var elementosUnicos = []

  // Itera sobre cada elemento del array combinado
  combinedArray.forEach(function (elemento) {
    // Verifica si el elemento no se encuentra en ambos arrays
    if (!arr1.includes(elemento) || !arr2.includes(elemento)) {
      // Agrega el elemento al array de elementos únicos
      elementosUnicos.push(elemento)
    }
  })

  return elementosUnicos
}
