import { useState } from 'react'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { manipulateDataToPaintSettingProperties } from '../../utils/handleData'
import BadgeFileExtension from '../Badge/BadgeFileExtension'

export function CustomToggle({ children, eventKey }) {
  const [isOpened, setIsOpened] = useState(false)
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    setIsOpened((previousState) => !previousState)
  )

  return (
    <div style={{ cursor: 'pointer' }} onClick={decoratedOnClick}>
      {isOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </div>
  )
}

const SingleSettingItem = ({ setting, children }) => {
  const manipulatedSetting = manipulateDataToPaintSettingProperties(setting)

  return (
    <Accordion defaultActiveKey="0">
      <Card style={{ marginBottom: '.4rem' }}>
        <Card.Header
          style={{
            display: 'flex',
            gap: '.5rem',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
            <CustomToggle eventKey="1" />
            <p style={{ margin: 0, fontWeight: 600 }}>{setting.nombre}</p>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}>
            <BadgeFileExtension extension={setting.extension} />
            {children}
          </div>
        </Card.Header>
        <Accordion.Collapse
          eventKey="1"
          style={{ padding: '1rem', fontSize: '12px' }}
        >
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '.75rem' }}
          >
            {Object.keys(manipulateDataToPaintSettingProperties(setting)).map(
              (category, index) => (
                <div key={index}>
                  <p style={{ marginBottom: '.5rem', fontWeight: 700 }}>
                    {category}
                  </p>
                  <div style={{ marginLeft: '2rem' }}>
                    {manipulatedSetting[category].map((displayName, index) => (
                      <p style={{ marginBottom: '.5rem' }} key={index}>
                        {displayName}
                      </p>
                    ))}
                  </div>
                </div>
              )
            )}
          </div>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
}

export default SingleSettingItem
