import React, { useEffect, useState } from 'react'
import Countdown from '../components/Countdown/Countdown'
import SuccessAnimation from '../components/Animation/SuccessAnimation'
import ErrorAnimation from '../components/Animation/ErrorAnimation'

const options = {
  success: {
    text: 'Tu pago fue realizado correctamente',
    color: '#4bb71b',
  },
  canceled: {
    text: 'Has cancelado la orden',
    color: 'red',
  },
}

const PaymentPage = () => {
  const [status, setStatus] = useState(null)
  const [freeTrial, setFreeTrial] = useState(false)
  const [redirectSettingsPage, setRedirectSettingsPage] = useState(false)

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)

    if (query.get('success')) setStatus('success')
    if (query.get('canceled')) setStatus('canceled')
    if (query.get('freetrial')) setFreeTrial(true)
    if (query.get('settings')) setRedirectSettingsPage(true)
  }, [])
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        padding: '5rem',
        // borderRadius: '1rem',
        // boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      }}
    >
      {status == 'success' ? <SuccessAnimation /> : <ErrorAnimation />}
      <p
        style={{
          color: options[status]?.color,
          fontSize: '2rem',
          fontWeight: '700',
        }}
      >
        {freeTrial ? 'Periodo de prueba comenzado' : options[status]?.text}
      </p>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '1rem',
          marginTop: '2rem',
        }}
      >
        {/* <Spinner style={{ width: '30px', height: '30px' }} /> */}
        <span>
          Redirigiendo en{' '}
          <Countdown
            time={5}
            redirect={redirectSettingsPage ? '/settings/subscription' : '/'}
          />{' '}
          segundos...
        </span>
      </div>
    </div>
  )
}

export default PaymentPage
