import { useEffect, useState, useContext } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { getAllSettingsByUser } from '../../api/latamApi'
import Spinner from 'react-bootstrap/Spinner'
import { Checkbox, Menu, MenuItem, Button } from '@mui/material'
import { toast } from 'react-toastify'
import Helpers, { allBubbleNodes } from '../Viewer/Viewer-helpers'
import {
  downloadJsonFile,
  handleDataToDownloadRvt,
} from '../../utils/downloadJson'
import SingleSettingItem from '../ModalManageSettings/SingleSettingItem'
import {
  checkFileExtension,
  getVersionByUrn,
  sortSettingsByFileExtension,
} from '../../utils/handleFileExtension'
import LoaderDownloadData from '../Spinner/LoaderDownloadData'
import { UserContext } from '../../context/UserContext'
import { ViewerContext } from '../../context/ViewerContext'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { getAllViewablesFromDoc } from '../../utils/BubbleNodes'

const ModalDownloadData = (props) => {
  const { show, onHide } = props
  const { user } = useContext(UserContext)
  const [isDownloadJson, setIsDownloadJson] = useState(false)
  const [settings, setSettings] = useState(null)
  const [selectedSetting, setSelectedSetting] = useState(null)
  const [selectedExtension, setSelectedExtension] = useState(null)
  const [loading, setLoading] = useState(true)
  const { selectedGuidViewCurrentModel } = useContext(ViewerContext)
  const [anchorEl, setAnchorEl] = useState(null)

  const getSettings = async () => {
    setLoading(true)
    try {
      const { data: settings } = await getAllSettingsByUser()
      setSettings(settings)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const handleOnChange = (setting, e) => {
    if (e.target.checked) {
      setSelectedSetting(setting)
    } else {
      setSelectedSetting(null)
    }
  }

  const handleDownloadJson = async () => {
    if (!selectedSetting) {
      return toast.error(
        'Selecciona una configuración para descargar los datos'
      )
    }
    await handleDownloadClick()
  }

  const handleDownloadClick = async () => {
    setAnchorEl(null)
    setIsDownloadJson(true)
    try {
      const extension = Helpers.getFileExtensionModel()

      const data = await Helpers.getData(
        selectedSetting.propiedades.map((property) => property.name)
      )

      const defaultView = getAllViewablesFromDoc(allBubbleNodes)[0][0].data.guid
      const defaultViewName =
        getAllViewablesFromDoc(allBubbleNodes)[0][0].data.name

      const currentViewName = selectedGuidViewCurrentModel
        ? selectedGuidViewCurrentModel[0]?.data?.name
        : defaultViewName

      const version = getVersionByUrn(Helpers.getUrnViewer())
      // console.log('Urn:', decodeBase64(Helpers.getUrnViewer()))
      const fileName = `${Helpers.getModelFileName()}_v${version}__${
        selectedSetting.nombre
      }__${currentViewName}`
      if (extension == 'rvt') {
        const handleData = handleDataToDownloadRvt(
          data,
          Helpers.getUrnViewer(),
          selectedGuidViewCurrentModel
            ? selectedGuidViewCurrentModel[0]?.data?.guid
            : defaultView
        )

        // Quitar espacios y sustituirlos por "_", lowercase y quitar acentos
        downloadJsonFile(handleData, fileName)
      }
      if (extension == 'nwc' || extension == 'nwd') {
        // ! SUSTITUIR NEWARRAY POR ARRAY
        const filteredProperties = data.map((element) => {
          const newProperties = element.properties.filter((property) => {
            return selectedSetting.propiedades.some((elem) => {
              return (
                elem.name === property.displayName &&
                elem.cat === property.displayCategory
              )
            })
          })

          if (newProperties.length == 0) {
            // console.log('no hay propiedades', element.dbId)
            return
          }
          return { ...element, properties: newProperties }
        })
        const newData = handleDataToDownloadRvt(
          filteredProperties.filter((property) => property !== undefined),
          Helpers.getUrnViewer(),
          selectedGuidViewCurrentModel
            ? selectedGuidViewCurrentModel[0]?.data?.guid
            : defaultView
        )

        downloadJsonFile(newData, fileName)
      }
      setIsDownloadJson(false)
      onHide()
      toast.success('Información descargada correctamente.')
    } catch (e) {
      setIsDownloadJson(false)
      toast.error('Error al descargar información. Inténtelo de nuevo.')
    }
  }

  useEffect(() => {
    ;(async () => {
      let extension = await Helpers.getFileExtensionModel()
      setSelectedExtension(extension)
    })()
  }, [show])

  useEffect(() => {
    if (show) {
      getSettings()
    }
    if (!show) {
      setSelectedSetting(null)
    }
  }, [show])

  return (
    <Offcanvas {...props}>
      <div
        style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
      >
        <Offcanvas.Header>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Offcanvas.Title>Descargar Datos</Offcanvas.Title>
            <Button
              style={{
                backgroundColor: '#2891c2',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                fontSize: '12px',
              }}
              onClick={(e) => setAnchorEl(e.currentTarget)}
              endIcon={
                !!anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
              }
            >
              Descargar
            </Button>
          </div>
        </Offcanvas.Header>
        {isDownloadJson ? (
          <LoaderDownloadData />
        ) : (
          <>
            <Offcanvas.Body style={{ flex: 1 }}>
              <p
                style={{
                  fontStyle: 'oblique',
                  width: 'auto',
                  marginBottom: '1rem',
                }}
              >
                Selecciona una configuración para descargar datos:
              </p>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Spinner />
                </div>
              ) : (
                sortSettingsByFileExtension(settings, selectedExtension).map(
                  (setting) => (
                    <SingleSettingItem key={setting._id} setting={setting}>
                      <Checkbox
                        disabled={
                          !checkFileExtension(
                            selectedExtension,
                            setting.extension
                          )
                        }
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                        onChange={(e) => handleOnChange(setting, e)}
                        checked={selectedSetting?._id == setting?._id}
                      />
                    </SingleSettingItem>
                  )
                )
              )}
            </Offcanvas.Body>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '1rem',
                // marginRight: '.5rem',
                padding: '1rem',
              }}
            >
              <Button
                className="btn-primary btn btn-strap"
                variant="secondary"
                onClick={props.onHide}
              >
                Cancelar
              </Button>
            </div>

            {/* MENU PARA DESCARGAR DATOS EN EXCEL Y JSON */}
            <Menu
              anchorEl={anchorEl}
              // anchorOrigin={{
              //   vertical: 'top',
              //   horizontal: 'right',
              // }}
              anchorPosition={{ top: 0, right: 0 }}
              open={!!anchorEl}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                style={{
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                }}
                onClick={handleDownloadClick}
              >
                JSON
              </MenuItem>
              {/* <MenuItem
                style={{
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                }}
                onClick={() =>
                  handleExportExcel(
                    selectedGuidViewCurrentModel,
                    selectedSetting,
                    false
                  )
                }
                disabled={true}
              >
                EXCEL
              </MenuItem>
              <MenuItem
                style={{
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                }}
                onClick={() =>
                  handleExportExcel(
                    selectedGuidViewCurrentModel,
                    selectedSetting,
                    true
                  )
                }
                disabled={true}
              >
                CSV
              </MenuItem> */}
            </Menu>
          </>
        )}
      </div>
    </Offcanvas>
  )
}

export default ModalDownloadData
