import Button from 'react-bootstrap/esm/Button'
import { createTrialSubscription } from '../../../api/latamApi'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import axios from 'axios'

const ProductButtonFree = () => {
  const { isAuthenticated } = useAuth0()
  const navigate = useNavigate()

  const handleFreeTrial = () => {
    createTrialSubscription()
      .then((res) => {
        navigate('/payment?success=true&freetrial=true')
      })
      .catch((e) => {
        if (e?.response?.data?.show) return toast.error(e.response.data.message)
      })
  }

  return (
    // quiero que el div que contiene el boton ocupe todo el espacio disponible
    <Button
    className='chilling-button'
      onClick={
        isAuthenticated ? handleFreeTrial : () => navigate('/register-required')
      }
      style={{
        marginTop: '2rem',
        width: '100%',
        fontSize: '1.5rem',
        fontWeight: '600',
        padding: '1rem',
      }}
    >
      {isAuthenticated ? 'Suscribirse' : 'Registrarse'}
    </Button>
  )
}

const ProductButton = ({ product }) => {
  const { isAuthenticated } = useAuth0()
  const { id } = product
  const navigate = useNavigate()
  useEffect(() => {
    console.log('isAuthenticated', isAuthenticated)
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault()

    if (isAuthenticated) {
      try {
        const { data } = await axios.post(
          `/api/v1/stripe/session?price_id=${product.id}`
        )
        if (data?.data?.url) {
          window.location.href = data?.data?.url
        }
      } catch (e) {
        if (e?.response?.data?.show)
          return toast.error(e?.response?.data?.message)
      }
    } else {
      navigate('/register-required')
    }
  }

  if (id === process.env.REACT_APP_STRIPE_PRICEID_TRIAL)
    return <ProductButtonFree />
  if (!isAuthenticated)
    return (
      <Button
        style={{
          marginTop: '2rem',
          width: '100%',
          fontSize: '1.5rem',
          fontWeight: '600',
          padding: '1rem',
        }}
        onClick={() => navigate('/register-required')}
      >
        Registrarse
      </Button>
    )
  return (
    <form
      method="POST"
      action={
        isAuthenticated
          ? `${process.env.REACT_APP_AUTH0_LOGOUT_URL}/api/v1/stripe/session?price_id=${product.id}`
          : navigate('/register-required')
      }
      onSubmit={onSubmit}
    >
      <Button
        type="submit"
        style={{
          marginTop: '2rem',
          width: '100%',
          fontSize: '1.5rem',
          fontWeight: '600',
          padding: '1rem',
        }}
      >
        Suscribirse
      </Button>
    </form>
  )
}

export default ProductButton
