import axios from 'axios'
import InspireTree from 'inspire-tree'
import InspireTreeDom from 'inspire-tree-dom'
import 'inspire-tree-dom/dist/inspire-tree-light.css'

const createTreeNode = (id, text, icon, children = false, selected = false) => {
  return {
    id,
    text,
    children,
    itree: { state: { selected: selected }, icon },
  }
}

async function getHubs() {
  const hubs = await axios.get('/api/v1/hubs')
  console.log('hubs', hubs)
  return hubs.data.map((hub) =>
    createTreeNode(`hub|${hub.id}`, hub.attributes.name, 'icon-hub', true)
  )
}

async function getProjects(hubId) {
  const projects = await axios.get(`/api/v1/hubs/${hubId}/projects`)
  return projects.data.map((project) =>
    createTreeNode(
      `project|${hubId}|${project.id}`,
      project.attributes.name,
      'icon-project',
      true
    )
  )
}

async function getContents(hubId, projectId, folderId = null) {
  const contents = await axios.get(
    `/api/v1/hubs/${hubId}/projects/${projectId}/contents` +
      (folderId ? `?folder_id=${folderId}` : '')
  )
  console.log('CONTENTS: ', contents)
  return contents.data.map((item) => {
    if (item.type === 'folders') {
      return createTreeNode(
        `folder|${hubId}|${projectId}|${item.id}`,
        item.attributes.displayName,
        'icon-my-folder',
        true
      )
    } else {
      console.log('ITEM: ', item)
      return createTreeNode(
        `item|${hubId}|${projectId}|${item.id}`,
        item.attributes.displayName,
        'icon-item',
        false
      )
    }
  })
}

async function getVersions(
  hubId,
  projectId,
  itemId,
  onSelectionChanged,
  versionName,
  versionsArray,
  setSelectedGuidViewCurrentModel,
  handleRelaunchViewer
) {
  const versions = await axios.get(
    `/api/v1/hubs/${hubId}/projects/${projectId}/contents/${itemId}/versions`
  )
  let lastVersion = versions.data[0]
  let nameVersion =
    'v' +
    lastVersion.attributes.versionNumber +
    ' - ' +
    lastVersion.attributes.name
  versionsArray(versions.data)
  onSelectionChanged(lastVersion.id)
  setSelectedGuidViewCurrentModel(null)
  handleRelaunchViewer()
  versionName(nameVersion)

  // CREACION DEL NODO CON LA ULTIMA VERSION SELECCIONADA Y EL RESTO DE VERSIONES
  // return versions.data.map((version, index) => {
  //   let nameVersion =
  //     'v' + version.attributes.versionNumber + ' - ' + version.attributes.name
  //   if (index === 0) {
  //     return createTreeNode(
  //       `version|${version.id}|${nameVersion}`,
  //       version.attributes.createTime,
  //       'icon-version',
  //       false,
  //       true
  //     )
  //   } else {
  //     return createTreeNode(
  //       `version|${version.id}|${nameVersion}`,
  //       version.attributes.createTime,
  //       'icon-version'
  //     )
  //   }
  // })
}

export const initTree = (
  selector,
  onSelectionChanged,
  versionName,
  versionsArray,
  setSelectedGuidViewCurrentModel,
  handleRelaunchViewer
) => {
  // See http://inspire-tree.com
  const tree = new InspireTree({
    data: function (node) {
      if (!node || !node.id) {
        return getHubs()
      } else {
        const tokens = node.id.split('|')
        switch (tokens[0]) {
          case 'hub':
            return getProjects(tokens[1])
          case 'project':
            return getContents(tokens[1], tokens[2])
          case 'folder':
            return getContents(tokens[1], tokens[2], tokens[3])
          case 'item':
            return getVersions(
              tokens[1],
              tokens[2],
              tokens[3],
              onSelectionChanged,
              versionName,
              versionsArray,
              setSelectedGuidViewCurrentModel,
              handleRelaunchViewer
            )
          default:
            return []
        }
      }
    },
  })
  tree.on('node.click', function (event, node) {
    event.preventDefault()
    const tokens = node.id.split('|')

    // si es un item vamos a

    if (tokens[0] === 'item') {
      getVersions(
        tokens[1],
        tokens[2],
        tokens[3],
        onSelectionChanged,
        versionName,
        versionsArray,
        setSelectedGuidViewCurrentModel,
        handleRelaunchViewer
      )
    }

    if (tokens[0] === 'version') {
      onSelectionChanged(tokens[1])
      versionName(tokens[2])
    }
  })
  return new InspireTreeDom(tree, { target: selector })
}
