import React, { Suspense, useContext, useEffect, useState } from 'react'
import ProductsContainerSettings from '../components/ProductsSetting/ProductsContainerSettings'
import InvoicesSettings from '../components/ProductsSetting/InvoicesSettings/InvoicesSettings'
import SkeletonTable from '../components/Spinner/SkeletonTable'
import { ErrorBoundary } from 'react-error-boundary'
import GeneralError from '../components/Error/GeneralError'
import { UserContext } from '../context/UserContext'
import { convertirTimestampAFecha } from '../helpers/stripeHelper'
import UsersPremium from '../components/Products/UsersPremium'

const SubscriptionPage = () => {
  const {
    subscription,
    endTrial,
    activeCurrentSubscription,
    loadingSubscription,
    expiresSubscription,
    user,
  } = useContext(UserContext)

  return loadingSubscription ? (
    <SkeletonTable />
  ) : (
    <div
      style={{
        paddingLeft: '5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '4rem',
      }}
    >
      <ErrorBoundary fallback={<GeneralError>Error</GeneralError>}>
        <Suspense fallback={<SkeletonTable />}>
          <div className="settings-section-container">
            {!user?.isPremium && (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
              >
                <h3 style={{ margin: '0', padding: '0' }}>BIMsualize PRO</h3>
                {activeCurrentSubscription ? (
                  <div
                    style={{
                      backgroundColor: '#F3FCF3',
                      border: '1px solid',
                      borderColor: '#D5EBDE',
                      padding: '4px 12px',
                    }}
                  >
                    <p
                      style={{
                        color: '#28AA54',
                        margin: 0,
                      }}
                    >
                      Renovación automática el{' '}
                      {convertirTimestampAFecha(
                        activeCurrentSubscription.current_period_end
                      )}
                    </p>
                  </div>
                ) : expiresSubscription ? (
                  <div
                    style={{
                      backgroundColor: '#E7E3E3',
                      border: '1px solid',
                      borderColor: '#EBD1D8',
                      padding: '4px 12px',
                    }}
                  >
                    <p
                      style={{
                        color: '#E17586',
                        margin: 0,
                      }}
                    >
                      Caduca el {convertirTimestampAFecha(expiresSubscription)}
                    </p>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: '#E7E3E3',
                      border: '1px solid',
                      borderColor: '#EBD1D8',
                      padding: '4px 12px',
                    }}
                  >
                    <p
                      style={{
                        color: '#E17586',
                        margin: 0,
                      }}
                    >
                      No hay ninguna suscripción activa
                    </p>
                  </div>
                )}
                {/* {endTrial && (
                <h4 style={{ margin: '0', padding: '0' }}>
                  (Le quedan {endTrial} días de prueba)
                </h4>
              )} */}
              </div>
            )}
            {!user?.isPremium ? (
              <ProductsContainerSettings
                currentActiveSubscription={activeCurrentSubscription}
              />
            ) : (
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
              >
                <p style={{ margin: '0' }}>
                  Puede acceder a cualquier funcionalidad de BIMsualize
                </p>
                <div
                  style={{
                    backgroundColor: '#F3FCF3',
                    border: '1px solid',
                    borderColor: '#D5EBDE',
                    padding: '4px 12px',
                  }}
                >
                  <p
                    style={{
                      color: '#28AA54',
                      margin: 0,
                    }}
                  >
                    Usuario PREMIUM
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="settings-section-container">
            <h3>Facturas</h3>
            <InvoicesSettings />
          </div>
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}

export default SubscriptionPage
