import { Button, CircularProgress } from '@mui/material'
import axios from 'axios'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import { default as CancelButton } from 'react-bootstrap/esm/Button'

const ModalConfirmChangeSubscription = ({
  show,
  onHide,
  title,
  text,
  price,
  textButton = 'Aceptar',
}) => {
  const [loadingRedirect, setLoadingRedirect] = useState(false)
  const action = `/api/v1/stripe/session?price_id=${price.id}&redirect=false`

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      const { data } = await axios.post(action)
      if (data?.data?.url) {
        window.location.href = data?.data?.url
      }
    } catch (e) {
      if (e?.response?.data?.show)
        return toast.error(e?.response?.data?.message)
    }
  }
  return (
    <Modal
      show={show}
      animation={false}
      fade={false}
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', justifyContent: 'center' }}>
        <p style={{ display: 'flex', margin: 0 }}>{text}</p>
      </Modal.Body>
      <Modal.Footer>
        <CancelButton variant="secondary" onClick={onHide}>
          Volver
        </CancelButton>
        <form method="POST" onSubmit={onSubmit}>
          <Button
            endIcon={
              loadingRedirect && (
                <CircularProgress
                  style={{
                    color: 'white',
                  }}
                  size={20}
                />
              )
            }
            style={{
              textTransform: 'none',
              fontSize: '12px',
              fontWeight: '400',
              backgroundColor: '#2891c2',
              color: 'white',
            }}
            onClick={() => setLoadingRedirect(true)}
            type="submit"
          >
            {textButton}
          </Button>
        </form>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalConfirmChangeSubscription
