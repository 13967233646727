import { useEffect, useContext, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { UserContext } from '../../context/UserContext'
import { deleteSetting, getAllSettingsByUser } from '../../api/latamApi'
import DeleteIcon from '@mui/icons-material/Delete'
import SingleSettingItem from './SingleSettingItem'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
import CustomTooltip from '../Tooltip/CustomTooltip'
import ModalRemoveSetting from './ModalRemoveSetting'
import { toast } from 'react-toastify'
import BadgeFileExtension from '../Badge/BadgeFileExtension'

const ModalManageSettings = (props) => {
  const { user } = useContext(UserContext)
  const [settings, setSettings] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedSettingToDelete, setSelectedSettingToDelete] = useState(null)

  const getSettings = async () => {
    setIsLoading(true)
    try {
      const { data: settings } = await getAllSettingsByUser()
      setSettings(settings)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  const handleDOpenModalDeleteSetting = (setting) => {
    setSelectedSettingToDelete(setting)
    setShowDeleteModal(true)
  }

  const handleDeleteSetting = async () => {
    try {
      await deleteSetting(selectedSettingToDelete?._id)
      setShowDeleteModal(false)
      getSettings()

      toast.success('Configuración eliminada correctamente')
    } catch (e) {
      setShowDeleteModal(false)
      toast.error(
        'Hubo un error al eliminar la configuración. Inténtalo otra vez.'
      )
    }
  }

  useEffect(() => {
    if (!showDeleteModal) {
      setSelectedSettingToDelete(null)
    }
  }, [showDeleteModal])

  useEffect(() => {
    if (props.show) {
      getSettings()
    }
    if (!props.show) {
      setSettings(null)
    }
  }, [props.show])

  useEffect(() => {
    console.log('LAS SETINGS: ', settings)
  }, [settings])

  return (
    <>
      <ModalRemoveSetting
        name={selectedSettingToDelete?.nombre}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        onDelete={handleDeleteSetting}
      />
      <Offcanvas {...props}>
        <div
          style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}
        >
          <Offcanvas.Header>
            <Offcanvas.Title>Administrar configuraciones</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body style={{ flex: 1 }}>
            {isLoading ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner />
              </div>
            ) : (
              settings &&
              settings.map((setting) => (
                <SingleSettingItem key={setting._id} setting={setting}>
                  <div style={{ display: 'flex' }}>
                    <CustomTooltip text="Eliminar">
                      <DeleteIcon
                        fontSize="large"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleDOpenModalDeleteSetting(setting)}
                      />
                    </CustomTooltip>
                  </div>
                </SingleSettingItem>
              ))
            )}
          </Offcanvas.Body>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '1rem',
              marginRight: '.5rem',
              marginBottom: '1rem',
            }}
          >
            <Button variant="secondary" onClick={props.onHide}>
              Cancelar
            </Button>
          </div>
        </div>
      </Offcanvas>
    </>
  )
}

export default ModalManageSettings
