import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Countdown = ({ time, redirect }) => {
  const [countdown, setCountdown] = useState(time)
  const navigate = useNavigate()

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown > 0) setCountdown(countdown - 1)
      else {
        clearInterval(interval)
        navigate(redirect)
      }
    }, 1000)
  }, [countdown, navigate, redirect])

  return <b>{countdown}</b>
}

export default Countdown
