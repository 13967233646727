import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import NavbarLat from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import LegalFooter from '../components/Footer/LegalFooter'
import { Container } from 'react-bootstrap'
import NavbarSettingsPage from '../components/Navbar/NavbarSettingsPage'
import { withAuthenticationRequired } from '@auth0/auth0-react'

const SettingsLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const redirectToSubscription = () => {
    if (location.pathname === '/settings') {
      console.log('hola')
      navigate('/settings/subscription')
    }
    return null
  }

  useEffect(() => {
    redirectToSubscription()
  }, [location.pathname])

  return (
    <div
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      {/* {redirectToSubscription()} */}
      <NavbarLat />
      <Container fluid className="settings-layout-content">
        <h1>Ajustes</h1>
        <div className="settings-content-container">
          <NavbarSettingsPage />
          <Outlet />
        </div>
      </Container>
      <Footer />
      <LegalFooter />
    </div>
  )
}

const SettingsLayoutProtected = withAuthenticationRequired(() => (
  <SettingsLayout />
))
export default SettingsLayoutProtected
