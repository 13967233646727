import ContentLoader from 'react-content-loader'

const SkeletonTable = (props) => {
  return (
    <ContentLoader
      speed={2}
      width={1200}
      height={450}
      viewBox="0 0 1200 450"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="0" y="0" rx="0" ry="0" width="150" height="30" />
      <rect x="0" y="38" rx="0" ry="0" width="350" height="120" />
      <rect x="400" y="38" rx="0" ry="0" width="350" height="120" />
      <rect x="800" y="38" rx="0" ry="0" width="350" height="120" />
      <rect x="0" y="212" rx="0" ry="0" width="150" height="30" />
      <rect x="0" y="250" rx="0" ry="0" width="1000" height="40" />
      <rect x="0" y="300" rx="0" ry="0" width="1000" height="40" />
      <rect x="0" y="350" rx="0" ry="0" width="1000" height="40" />
      <rect x="0" y="450" rx="0" ry="0" width="1000" height="40" />
    </ContentLoader>
  )
}

export default SkeletonTable
