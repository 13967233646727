import React, { useContext, useEffect, useMemo } from 'react'
import AccTree from '../AccTree/AccTree'
import { UserContext } from '../../context/UserContext'
import {
  freeTrialDaysUntilExpired,
  getDaysLeftToExpire,
  getExpiredDate,
  restarFechas,
} from '../../utils/restarFechas'
import { createTrialSubscription } from '../../api/latamApi'
import Button from 'react-bootstrap/Button'
import { Navigate, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const SideTreeMenu = ({ versionName, onIdSelected, versionArray }) => {
  const { user, subscription, areCorrectCredentials, refetchSubscription } =
    useContext(UserContext)
  console.log('subscription', subscription)
  const navigate = useNavigate()

  const visibleTree = useMemo(() => {
    if (user?.isPremium) return true
    if (subscription?.length > 0) {
      return subscription.some(
        (sub) => sub.status === 'active' || sub.status === 'trialing'
      )
    }
    return false
  }, [user, subscription])

  const handleFreeTrial = async () => {
    try {
      await createTrialSubscription()
      refetchSubscription()
      toast.success('Periodo de prueba iniciado')
    } catch (e) {
      toast.error(
        'Hubo un error al iniciar el periodo de prueba. Vuélvelo a intentar'
      )
    }
  }

  useEffect(() => {
    console.log('visibleTree', visibleTree)
  }, [visibleTree])

  return (
    <div
      style={{
        minWidth: 'fit-content',
        width: '100%',
      }}
    >
      {!areCorrectCredentials && (
        <p style={{ textAlign: 'center', marginTop: '1rem', color: 'red' }}>
          El usuario logueado de la aplicación debe de coincidir con el de
          Autodesk.
        </p>
      )}
      {/* {user?.freeTrial == 'unstarted' &&
      subscription?.length == 0 &&
      !user?.isPremium ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Button variant="primary" onClick={handleFreeTrial}>
            Empezar periodo de prueba de 30 días
          </Button>
          <p style={{ fontSize: '1.5rem' }}>o</p>
          <Button variant="primary" onClick={() => navigate('/precios')}>
            Suscribirse a la versión PRO
          </Button>
        </div>
      ) : null} */}

      {!visibleTree && (
        <p style={{ textAlign: 'center' }}>
          Suscríbete a la versión PRO para poder visualizar el contenido
        </p>
      )}

      {
        <AccTree
          versionName={versionName}
          versionArray={versionArray}
          onIdSelected={onIdSelected}
          visibleTree={visibleTree}
          isCorrectUser={areCorrectCredentials}
        />
      }
    </div>
  )
}

export default SideTreeMenu
