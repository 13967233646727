import { Chip } from '@mui/material'
import { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { isEmail } from '../../../utils/emailValid'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify'

const ChipForm = ({ emails, setEmails }) => {
  const { user: userAuth0 } = useAuth0()
  const [user, setUser] = useState('')
  const [error, setError] = useState(null)

  const isValidEmail = (email) => {
    let error = null

    if (!isEmail(email)) {
      error = `${email} no es una dirección de correo electrónico válida.`
    }
    if (emails.includes(email)) {
      error = `${email} ya ha sido añadido.`
    }
    if (error) {
      setError(error)
      return false
    }
    return true
  }

  const handleOnChange = (e) => {
    setError(null)
    setUser(e.target.value)
  }

  const handleOnKeyDown = (e) => {
    if (['Enter', 'Tab', ','].includes(e.key)) {
      e.preventDefault()
      let email = user.trim()
      if (email == userAuth0.email)
        return toast.error('No puedes añadirte a ti mismo como invitado.')
      if (email && isValidEmail(user, emails)) {
        setUser('')
        setEmails((prevState) => [...prevState, email])
      }
    }
  }

  const handleDelete = (deletedEmail) => {
    setEmails((prevState) =>
      prevState.filter((email) => email !== deletedEmail)
    )
  }

  return (
    <>
      <InputGroup
        size="sm"
        className="mb-3"
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <Form.Label>
          Invitados {emails.length > 0 && `(${emails.length})`}
        </Form.Label>
        <Form.Control
          value={user}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
          isInvalid={error}
          placeholder="Escribe un correo electrónico y pulsa 'Enter'"
        />
      </InputGroup>
      {error && <p style={{ fontSize: '1rem', color: 'red' }}>{error}</p>}
      {emails.length > 0 && (
        <div className="chips-container">
          {emails.map((email) => (
            <Chip
              key={email}
              label={email}
              onDelete={() => handleDelete(email)}
              className="chip-item"
            />
          ))}
        </div>
      )}
    </>
  )
}

export default ChipForm
