export const getBubbleNodesInfo = (doc) => {
  const allBubbleNodes = doc.getRoot().search({ type: 'geometry', role: '3d' })
  console.log('All Bubble Nodes', allBubbleNodes)

  // Por defecto, el modelo muestra la vista por defecto que tiene el name 'Default' (CREOOOOOO)
  const defaultGeometry = doc.getRoot().getDefaultGeometry()
  console.log('Default Geometry', defaultGeometry)

  const allViewables = allBubbleNodes.map((bubbleNode, i) => ({
    [`bubbleNode${i}`]: bubbleNode.getNamedViews(),
  }))

  console.log('getBubbleNodesInfo allVieables', allViewables)
  console.log('getBubbleNodesInfo allBubblesnodes', allBubbleNodes)
  return allBubbleNodes
}

export const getAllViewablesFromDoc = (allBubbleNodes) => {
  const allViewables = allBubbleNodes.map((bubbleNode, i) =>
    bubbleNode.getNamedViews()
  )

  console.log('Named Views', allViewables)
  return allViewables
}
