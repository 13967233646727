import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from '../pages/Home'
import Recursos from '../pages/Recursos'
import Precios from '../pages/Precios'
import Documentacion from '../pages/Documentacion'
import LatamViewer from '../pages/LatamViewer'

import { ViewerProvider } from '../context/ViewerContext'
import ProtectedRoute from '../components/Auth0/ProtectedRoute'
import { UserProvider } from '../context/UserContext'
import { withAuthenticationRequired } from '@auth0/auth0-react'
import PaymentPage from '../pages/PaymentPage'
import RootLayout from '../layout/RootLayout'
import RegisterRequired from '../pages/RegisterRequired'
import SettingsLayout from '../layout/SettingsLayout'
import SubscriptionPage from '../pages/SubscriptionPage'
import useUserLocation from '../hooks/useUserLocation'

const ProtectedLocationComponent = () => {
  const { isLoading, isUserAllowed } = useUserLocation()

  if (isLoading) return
  if (isUserAllowed) return <MyProtectedComponent />
  return (
    <RootLayout center={true}>
      <RegisterRequired />
    </RootLayout>
  )
}

const MyProtectedComponent = withAuthenticationRequired(() => (
  // <UserProvider>
  <ViewerProvider>
    <LatamViewer />
  </ViewerProvider>
  // </UserProvider>
))

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/recursos" element={<Recursos />} />
        <Route path="/precios" element={<Precios />} />
        <Route path="/app" element={<ProtectedLocationComponent />} />
        <Route
          path="/payment"
          element={
            <RootLayout center={true}>
              <PaymentPage />
            </RootLayout>
          }
        />
        <Route
          path="/register-required"
          element={
            <RootLayout center={true}>
              <RegisterRequired />
            </RootLayout>
          }
        />
        <Route path="/settings" element={<SettingsLayout />}>
          <Route index path="subscription" element={<SubscriptionPage />} />
        </Route>
      </Routes>
    </Router>
  )
}

export default AppRouter
