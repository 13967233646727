import React, { useMemo, useState } from 'react'
import AccordionAuthorize from './components/AccordionAuthorize'
import EditAuthorizeContent from './ModalEditAuthorization'
import { useAuth0 } from '@auth0/auth0-react'

const ManageAuthorizationContainer = ({ authorizations }) => {
  const { user } = useAuth0()
  const ownerAuthorizations = useMemo(() => {
    return authorizations.filter(
      (authorization) => authorization.owner === user.email
    )
  }, [authorizations])
  const guestsAuthorizations = useMemo(() => {
    return authorizations.filter((authorization) =>
      authorization.guests.includes(user.email)
    )
  })

  if (authorizations.length == 0)
    return (
      <p style={{ fontStyle: 'oblique', textAlign: 'center' }}>
        No hay ninguna autorización creada
      </p>
    )
  return (
    <>
      {ownerAuthorizations?.length > 0 && (
        <>
          <p>Mis autorizaciones</p>
          {ownerAuthorizations?.map((auth) => (
            <AccordionAuthorize key={auth?._id} authorize={auth} />
          ))}
        </>
      )}
      {guestsAuthorizations?.length > 0 && (
        <>
          <p>Invitaciones</p>
          {guestsAuthorizations?.map((auth) => (
            <AccordionAuthorize
              key={auth?._id}
              authorize={auth}
              isGuest={true}
            />
          ))}
        </>
      )}
    </>
  )
}

export default ManageAuthorizationContainer
