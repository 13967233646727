import { useQuery } from '@tanstack/react-query'
import ProductCardSetting from './ProductCardSetting'
import { motion } from 'framer-motion'
import { getAllPrices } from '../../api/latamApi'
import { UserContext } from '../../context/UserContext'
import { useContext, useEffect, useState } from 'react'
import ProductCard from '../Products/ProductCard'

const ProductsContainerSettings = ({ currentActiveSubscription }) => {
  const { data, isError } = useQuery(
    ['subscription'],
    () => getAllPrices(false),
    {
      suspense: true,
    }
  )
  const [currentPlanActive, setCurrentPlanActive] = useState(null)

  useEffect(() => {
    if (currentActiveSubscription) {
      console.log('currentActiveSubscription', currentActiveSubscription)
      // si hay una subscripcion activa recorremos los precios y buscamos el que coincida con el id de la subscripcion activa
      data.map((price) => {
        console.log('price', price)
        if (price.id === currentActiveSubscription.plan.id) {
          setCurrentPlanActive(price.id)
        }
      })
    } else {
      setCurrentPlanActive(null)
    }
  }, [currentActiveSubscription])

  const item = {
    hidden: {
      opacity: 0,
      x: -50,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
      },
    },
  }

  return (
    <>
      <div className="card-product-setting-container">
        {data.map((price) => (
          <ProductCardSetting
            noneActive={!!!currentPlanActive}
            actualActive={currentPlanActive == price.id ? true : false}
            thereIsActive={!!currentPlanActive}
            product={price}
            key={price.id}
          />
        ))}

        {console.log(data)}
      </div>
    </>
  )
}

export default ProductsContainerSettings
