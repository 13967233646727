import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import Button from 'react-bootstrap/esm/Button'
import { toast } from 'react-toastify'
import useUserLocation from '../hooks/useUserLocation'

const RegisterRequired = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0()
  const { isLoading, isUserAllowed } = useUserLocation()

  const onClickLoginWithRedirect = () => {
    if (isLoading) return
    if (isUserAllowed) {
      loginWithRedirect()
    } else {
      toast.error(
        'Lo sentimos, esta aplicación todavía no está disponible en tu región.'
      )
    }
  }
  const checkRegion = () => {
    ;(async () => {
      let myCountry = await axios.get('https://ipapi.co/json/')
      console.log('MY COUNTRY: ', myCountry.data.country)
      if (
        !isAuthenticated &&
        process.env.REACT_APP_COUNTRYS_LATAM.includes(myCountry.data.country)
      ) {
        await loginWithRedirect()
      } else {
        return toast.error(
          'Lo sentimos, esta aplicación todavía no está disponible en tu región.'
        )
      }
    })()
  }

  return (
    <div style={{ padding: '5rem', borderRadius: '1rem' }}>
      <div
        style={{ display: 'flex', justifyContent: 'center', margin: '3rem' }}
      >
        <img
          src={'./assets/img/logo_vimsualize.png'}
          style={{ width: '120px' }}
        />
      </div>
      <h1 style={{ textAlign: 'center', fontSize: '40px' }}>
        Se necesita registro
      </h1>
      <p
        style={{
          textAlign: 'center',
          fontSize: '1.5rem',
          marginBottom: '2rem',
        }}
      >
        Para suscribirse a alguno de los planes es necesario estar registrado en
        la aplicación
      </p>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={onClickLoginWithRedirect}
          disabled={isLoading}
          style={{ width: 'auto', fontSize: '1.5rem', padding: '1rem 5rem' }}
        >
          Registrarse
        </Button>
      </div>
    </div>
  )
}

export default RegisterRequired
