import { Divider, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import React from 'react'

const DropdownMenuItem = ({ text, Icon, onClick, isLast }) => {
  return (
    <>
      {isLast && <Divider />}
      <MenuItem style={{ fontSize: '2rem' }} onClick={onClick} className='dropdown-menu-item'>
        <ListItemIcon>
          <Icon fontSize="large" />
        </ListItemIcon>
        <ListItemText style={{ fontSize: '2rem' }}>{text}</ListItemText>
      </MenuItem>
    </>
  )
}

export default DropdownMenuItem
