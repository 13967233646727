import ContentLoader from 'react-content-loader'

const PricesCardsSkeleton = (props) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ContentLoader
        viewBox="0 0 820 500"
        height={500}
        width={820}
        backgroundColor="white"
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="140" y="30" rx="5" ry="5" width="260" height="50" />
        <rect x="410" y="30" rx="5" ry="5" width="260" height="50" />
        <rect x="10" y="100" rx="5" ry="5" width="260" height="350" />
        <rect x="280" y="100" rx="5" ry="5" width="260" height="350" />
        <rect x="550" y="100" rx="5" ry="5" width="260" height="350" />
      </ContentLoader>
    </div>
  )
}

export default PricesCardsSkeleton
