import React, { Suspense } from 'react'
import Container from 'react-bootstrap/Container'
import NavbarLat from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import LegalFooter from '../components/Footer/LegalFooter'
import PowerBiCard from '../components/Cards/PowerBiCard'
import PbivizContainer from '../components/Pbiviz/PbivizContainer'
import PbivizSkeleton from '../components/Spinner/Skeletons/PbivizSkelenton'

const examples = [
  {
    title: 'PowerBI Título 1',
    img: 'https://support.content.office.net/es-es/media/54628b72-3f3b-4dd9-9437-e3d8d201d334.png',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce enim orci, elementum in tincidunt nec, molestie eget sapien. Nulla at nibh enim. Nam eleifend, risus.',
  },
  {
    title: 'PowerBI Título 2',
    img: 'https://support.content.office.net/es-es/media/54628b72-3f3b-4dd9-9437-e3d8d201d334.png',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ornare, neque eget scelerisque euismod, arcu tortor rhoncus purus, quis placerat arcu nisl quis nulla. Cras.',
  },
  {
    title: 'PowerBI Título 3',
    img: 'https://support.content.office.net/es-es/media/54628b72-3f3b-4dd9-9437-e3d8d201d334.png',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dictum faucibus ex. Nam in justo volutpat, dignissim est id, consequat massa. Nam non est vestibulum.',
  },
  {
    title: 'PowerBI Título 4',
    img: 'https://support.content.office.net/es-es/media/54628b72-3f3b-4dd9-9437-e3d8d201d334.png',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dictum faucibus ex. Nam in justo volutpat, dignissim est id, consequat massa. Nam non est vestibulum.',
  },
  {
    title: 'PowerBI Título 5',
    img: 'https://support.content.office.net/es-es/media/54628b72-3f3b-4dd9-9437-e3d8d201d334.png',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dictum faucibus ex. Nam in justo volutpat, dignissim est id, consequat massa. Nam non est vestibulum.',
  },
  {
    title: 'PowerBI Título 6',
    img: 'https://support.content.office.net/es-es/media/54628b72-3f3b-4dd9-9437-e3d8d201d334.png',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dictum faucibus ex. Nam in justo volutpat, dignissim est id, consequat massa. Nam non est vestibulum.',
  },
  {
    title: 'PowerBI Título 7',
    img: 'https://support.content.office.net/es-es/media/54628b72-3f3b-4dd9-9437-e3d8d201d334.png',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dictum faucibus ex. Nam in justo volutpat, dignissim est id, consequat massa. Nam non est vestibulum.',
  },
]

const Recursos = () => {
  return (
    <>
      <NavbarLat />

      <Container
        fluid
        style={{ padding: '0', background: 'rgb(240, 242, 245)' }}
      >
        <div
          className="recursos-image-container"
          style={{ backgroundImage: 'url(assets/img/b3.jpg)' }}
          // style={{ backgroundImage: 'url(assets/img/b3.jpg)' }}
        >
          <div
            className="recursos-image"
            style={{
              width: '100%',
              height: '100%',
            }}
          >
            <h1
              style={{ color: 'white', textAlign: 'center', marginTop: '7rem' }}
            >
              Recursos
            </h1>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <h3
                style={{
                  color: 'white',
                  textAlign: 'center',
                  maxWidth: '50%',
                }}
              >
                Descarga nuestros ficheros para poder visualizar tu modelo BIM
                dentro de PowerBI
              </h3>
            </div>
          </div>
        </div>
        <div className="recursos-container">
          <Suspense fallback={<PbivizSkeleton />}>
            <PbivizContainer />
          </Suspense>
          <h3 style={{ textAlign: 'center', paddingTop: '5rem' }}>
            Plantillas de PowerBI
          </h3>
          <div className="cards-recursos-container">
            {examples.map((example) => (
              <PowerBiCard item={example} />
            ))}
          </div>
        </div>
      </Container>
      <Footer />
      <LegalFooter />
    </>
  )
}

export default Recursos
