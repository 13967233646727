import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { IconButton, Tooltip } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { toast } from 'react-toastify'

const ModalAuthorizeCode = ({ code, onHide, show, name }) => {
  const handleCopyCode = () => {
    navigator.clipboard.writeText(code)
    toast.success('Código copiado', {
      autoClose: 2000,
    })
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      animation={false}
      fade={false}
      backdrop="static"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{`Autorización ${name}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label style={{ margingLeft: '.2rem' }}>Código</Form.Label>
        <p style={{ margin: '0', marginBottom: '.5rem', fontSize: '1.2rem' }}>
          Usa este código para conectarte en Power BI
        </p>
        <div style={{ position: 'relative' }}>
          <Form.Control
            type="text"
            value={code}
            disabled={true}
            style={{ cursor: 'auto' }}
          />
          <Tooltip
            placement="right"
            title={<p style={{ marginBottom: 0, fontSize: '1rem' }}>Copiar</p>}
          >
            <IconButton className="copy-icon" onClick={handleCopyCode}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Aceptar</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalAuthorizeCode
