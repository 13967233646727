import { useEffect, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { getAllAuthorizationByUser } from '../../api/latamApi'
import Spinner from 'react-bootstrap/Spinner'
import ManageAuthorizationContainer from './ManageAuthorizationContainer'
import { toast } from 'react-toastify'
import { useQuery } from '@tanstack/react-query'
import Button from 'react-bootstrap/esm/Button'

const ModalManageAuthorizationContainer = (props) => {
  const { onHide } = props

  const { data, isFetching, isError } = useQuery(
    ['authorizations'],
    getAllAuthorizationByUser
  )

  if (isError) return toast.error('Hubo un error al cargar las autorizaciones')
  return (
    <Offcanvas {...props}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Offcanvas.Header>
          <Offcanvas.Title>Administrar autorizaciones</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ height: 'unset', overflowY: 'auto' }}>
          {isFetching ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spinner />
            </div>
          ) : (
            <ManageAuthorizationContainer authorizations={data.data} />
          )}
        </Offcanvas.Body>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '10px',
          }}
        >
          <Button variant="secondary" onClick={onHide}>
            Cancelar
          </Button>
        </div>
      </div>
    </Offcanvas>
  )
}

export default ModalManageAuthorizationContainer
