export const calculateDaysDifference = (timestamp) => {
    const currentDate = new Date();
    const targetDate = new Date(timestamp * 1000); // Convertir el timestamp a milisegundos

    // Cálculo de la diferencia en milisegundos
    const differenceInMilliseconds = targetDate - currentDate;

    // Cálculo de la diferencia en días
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    return differenceInDays;
};

export function convertirTimestampAFecha(timestamp) {
    const fecha = new Date(timestamp * 1000);
    const opcionesFecha = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return fecha.toLocaleDateString(undefined, opcionesFecha).replace(/\//g, '/');
}