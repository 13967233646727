import React, { useContext, useEffect, useState } from 'react'
import { cancelSubscription } from '../../../api/latamApi'
import { toast } from 'react-toastify'
import Button from 'react-bootstrap/esm/Button'
import { UserContext } from '../../../context/UserContext'
import ModalSubscription from '../../ModalConfirmChangeSubscription/ModalSubscription'

const options = {
  title: 'Cancelar suscripción',
  text: "¿Estás seguro que quieres cancelar tu suscripción? Una vez cancelada no podrás volver a acceder a los modelos 3D hasta que no se suscriba de nuevo.'",
}

const CancelSubscriptionButton = ({ subscription }) => {
  const {
    refetchSubscription,
    activeCurrentSubscription,
    setActiveCurrentSubscription,
  } = useContext(UserContext)
  const [isModalOpened, setIsModalOpened] = useState(false)

  const handleDeleteSubscription = () => {
    cancelSubscription(activeCurrentSubscription.id)
      .then((res) => {
        setIsModalOpened(false)
        refetchSubscription()
        setActiveCurrentSubscription(null)
          toast.success('Suscripción cancelada correctamente')
      })
      .catch((e) => {
        setIsModalOpened(false)
        toast.error(
          'Hubo un error al cancelar la suscripción. Inténtelo otra vez.'
        )
      })
  }

  return (
    <div>
      {isModalOpened && (
        <ModalSubscription
          show={isModalOpened}
          onHide={() => setIsModalOpened(false)}
          title={options.title}
          text={options.text}
          handleOnClick={handleDeleteSubscription}
        />
      )}
      <Button
        type="submit"
        variant="secondary"
        onClick={() => setIsModalOpened(true)}
        style={{
          marginTop: '2rem',
          // fontSize: '1.4rem',
          fontWeight: '600',
          background: 'white',
          border: '1px solid gray',
          color: 'gray',
        }}
      >
        Cancelar suscripción
      </Button>
    </div>
  )
}

export default CancelSubscriptionButton
