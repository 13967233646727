const ProductTitle = ({ product, noMargin }) => {
  console.log('PRODUCTOOOO: ', product)

  return (
    <>
      {/* <div style={{ height: '120px' }}>
        <img
          src={product.product_info?.images[0]}
          style={{ height: '100%' }}
        ></img>
      </div> */}

      <p
        style={{
          fontSize: '32px',
          fontWeight: '500',
          color: 'black',
          lineHeight: '42px',
          marginTop: noMargin ? '0' : '2rem',
        }}
      >
        {product.recurring.interval === 'month' && 'Pago mensual'}
        {product.recurring.interval === 'year' &&
          product.unit_amount != 0 &&
          'Pago anual'}
        {product.unit_amount === 0 && 'Prueba gratuita'}
      </p>
      {/* <p style={{ color: '#737373' }}>{product.product_info?.description}</p> */}
    </>
  )
}

export default ProductTitle
