import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const ModalRemoveSetting = ({name, show, onHide, onDelete}) => {

  return (
    <Modal show={show}  animation={false} fade={false} backdrop="static" centered>
        <Modal.Header>
            <Modal.Title>Eliminar configuración</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{display: "flex", justifyContent: "center"}}>
            <p style={{display: "flex", margin: 0 }}>¿Estás seguro qué quieres eliminar<p style={{fontWeight: 700, marginLeft: ".3rem", marginBottom: 0}}> {name}</p> ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Cancelar
          </Button>
          <Button type='submit' variant="danger" onClick={onDelete}>
            Eliminar
          </Button>
        </Modal.Footer>
      </Modal>
  )
}

export default ModalRemoveSetting