import axios from 'axios'
import { getBubbleNodesInfo } from '../../utils/BubbleNodes'

/* global Autodesk, THREE */
export let viewer
let viewableId
export let _instances
let _propertyNames
export let allBubbleNodes

// const getForgeToken = (callback) => {
//   const email = 'adrian@atbim.es'
//   fetch('/api/auth/token?email=' + encodeURIComponent(email), {
//     method: 'POST',
//   }).then((res) => {
//     res.json().then((data) => {
//       console.log('getForgeToken', data)

//       callback(data.access_token, data.expires_in)
//     })
//   })
// }

const getForgeToken = async (callback) => {
  const token = await axios.get('/api/auth/token')
  console.log('EL TOKEN: ', token)
  callback(token.data.access_token, token.data.expires_in)
}

const getUrnViewer = () => {
  return viewer.model.getSeedUrn()
}

const loadScript = (url, namespace) => {
  if (window[namespace] !== undefined) {
    return Promise.resolve()
  }
  return new Promise(function (resolve, reject) {
    const el = document.createElement('script')
    el.src = url
    el.onload = resolve
    el.onerror = reject
    document.head.appendChild(el)
  })
}

const loadStylesheet = (url) => {
  return new Promise(function (resolve, reject) {
    const el = document.createElement('link')
    el.rel = 'stylesheet'
    el.href = url
    el.onload = resolve
    el.onerror = reject
    document.head.appendChild(el)
  })
}

const launchViewer = async (
  div,
  urn,
  setAreViewerDbIdsLoaded,
  setAllPropertyNames,
  selectedGuidViewCurrentModel
) => {
  await loadScript(
    'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.js',
    'Autodesk'
  )
  await loadStylesheet(
    'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.css'
  )
  var options = {
    getAccessToken: getForgeToken,
    env: 'AutodeskProduction2',
    api: 'streamingV2',
    // ! Anterior
    //  env: 'AutodeskProduction',
    //  api:
    //    'derivativeV2' +
    //    (atob(urn.replace('_', '/')).indexOf('emea') > -1 ? '_EU' : ''), // handle BIM 360 US and EU regions
  }

  Autodesk.Viewing.Initializer(options, () => {
    viewer = new Autodesk.Viewing.GuiViewer3D(document.getElementById(div), {
      extensions: ['Autodesk.DocumentBrowser'],
    })
    Autodesk.Viewing.theExtensionManager.unregisterExtension(
      'Autodesk.DocumentBrowser'
    )
    viewer.start()
    var spinners = document.getElementsByClassName('forge-spinner')
    var spinner = spinners[0]
    spinner.classList.remove('forge-spinner')
    spinner.classList.add('loader')
    spinner.innerHTML =
      "<img src='./Logo_BIMsualize.png'  class='viewer-spinner' />"
    setAreViewerDbIdsLoaded && setAreViewerDbIdsLoaded(false)
    setAllPropertyNames && setAllPropertyNames(false)
    viewer.setTheme('light-theme')
    console.log('uuuuurn', urn)

    var documentId = 'urn:' + urn.replace('/', '_')
    // var documentId = 'urn:' + urn
    console.log('documentId', documentId)
    Autodesk.Viewing.Document.load(
      documentId,
      (doc) =>
        onDocumentLoadSuccess(
          doc,
          setAreViewerDbIdsLoaded,
          setAllPropertyNames,
          selectedGuidViewCurrentModel
        ),
      onDocumentLoadFailure
    )
  })
}

const onDocumentLoadSuccess = (
  doc,
  setAreViewerDbIdsLoaded,
  setAllPropertyNames,
  selectedGuidViewCurrentModel
) => {
  allBubbleNodes = getBubbleNodesInfo(doc)
  console.log('llega aquiiii')
  // console.log(doc.getRoot().findAllViewables()) // Esto no sé que es
  console.log('selectedGuidViewCurrentModel', selectedGuidViewCurrentModel)
  var viewables = selectedGuidViewCurrentModel
    ? doc.getRoot().findByGuid(selectedGuidViewCurrentModel[0].data.guid)
    : doc.getRoot().getDefaultGeometry()
  viewer.loadDocumentNode(doc, viewables).then((i) => {
    // any additional action here?
    viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, (event) => {
      return new Promise((resolve) => {
        startLeafNodes(setAreViewerDbIdsLoaded, setAllPropertyNames)
      })
    })
    // viewer.addEventListener(
    //   Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT,
    //   (event) => {
    //     return new Promise((resolve) => {
    //       startLeafNodes(setAreViewerDbIdsLoaded, setAllPropertyNames)
    //     })
    //   }
    // )
  })
}

const onDocumentLoadFailure = (viewerErrorCode, viewerErrorMsg) => {
  console.error(
    'onDocumentLoadFailure() - errorCode:' +
      viewerErrorCode +
      '\n- errorMessage:' +
      viewerErrorMsg
  )
}

const getAllLeafComponents = (viewer, callback) => {
  var cbCount = 0 // count pending callbacks
  var components = [] // store the results
  var tree // the instance tree

  function getLeafComponentsRec(parent) {
    cbCount++
    if (tree.getChildCount(parent) != 0) {
      tree.enumNodeChildren(
        parent,
        function (children) {
          getLeafComponentsRec(children)
        },
        false
      )
    } else {
      components.push(parent)
    }
    if (--cbCount == 0) callback(components)
  }
  viewer.getObjectTree(function (objectTree) {
    tree = objectTree
    var allLeafComponents = getLeafComponentsRec(tree.getRootId())
  })
}

const startLeafNodesNwd = (setAreViewerDbIdsLoaded, setAllPropertyNames) => {
  viewer.model.getBulkProperties(null, ['GUID'], (res) => {
    const dbIds = res.map((item) => item.dbId)
    _instances = dbIds
    console.log('dbIdsNwd: ', dbIds)
    getAllPropertyNames(setAllPropertyNames)
    setAreViewerDbIdsLoaded && setAreViewerDbIdsLoaded(true)
  })
}

const startLeafNodesIfc = (setAreViewerDbIdsLoaded, setAllPropertyNames) => {
  viewer.model.getBulkProperties(null, ['IfcTag'], (res) => {
    const dbIds = res.map((item) => item.dbId)
    _instances = dbIds
    console.log('dbIdsIfc: ', dbIds)
    getAllPropertyNames(setAllPropertyNames)
    setAreViewerDbIdsLoaded && setAreViewerDbIdsLoaded(true)
  })
}

const startLeafNodesRvt = (setAreViewerDbIdsLoaded, setAllPropertyNames) => {
  getAllLeafComponents(viewer, (dbIds) => {
    _instances = dbIds
    console.log('dbIdsRvt: ', dbIds)

    setAreViewerDbIdsLoaded && setAreViewerDbIdsLoaded(true)
    getAllPropertyNames(setAllPropertyNames)
    console.log('ya están los dbids!!!')
  })
}
const startLeafNodes = async (setAreViewerDbIdsLoaded, setAllPropertyNames) => {
  const extension = getFileExtensionModel()
  switch (extension) {
    case 'nwc':
      return startLeafNodesNwd(setAreViewerDbIdsLoaded, setAllPropertyNames)
    case 'nwd':
      return startLeafNodesNwd(setAreViewerDbIdsLoaded, setAllPropertyNames)
    case 'rvt':
      return startLeafNodesRvt(setAreViewerDbIdsLoaded, setAllPropertyNames)
    // case 'ifc':
    //   return startLeafNodesIfc(setAreViewerDbIdsLoaded, setAllPropertyNames)
    default:
      throw new Error('Extension file not found: ' + extension)
  }
}

const getPropertyNames = () => {
  if (_propertyNames) {
    return _propertyNames
  } else {
    return null
  }
}

const getAllPropertyNames = async (setAllPropertyNames) => {
  console.log('getAllPropertyName start')
  const promises = _instances.map(
    (dbId) =>
      new Promise((resolve) => {
        viewer.getProperties(dbId, (res) => {
          resolve(
            res.properties.map((p) => ({
              cat: p.displayCategory,
              name: p.displayName,
            }))
          )
        })
      })
  )

  const results = (await Promise.all(promises)).flat()
  console.log('getAllPropertyName finish promises')

  const hashTable = {}
  const unique = []
  for (const obj of results) {
    const key = obj.cat + obj.name
    if (!hashTable[key]) {
      hashTable[key] = true
      unique.push(obj)
    }
  }

  const grouped = unique.reduce((acc, obj) => {
    const category = obj.cat
    if (!acc[category]) {
      acc[category] = []
    }
    acc[category].push(obj)
    return acc
  }, {})
  console.log('getAllPropertyName finish')
  setAllPropertyNames(true)
  _propertyNames = grouped
  console.log('_propertyNames', _propertyNames)
  return grouped
}

const getDataAsync = (properties) => {
  return new Promise((resolve, reject) => {
    viewer.model.getBulkProperties(
      _instances,
      properties,
      (res) => {
        resolve(res)
      },
      (err) => reject(err)
    )
  })
}
const getModelFileName = () => {
  const fileName = viewer.model
    .getData()
    .loadOptions.bubbleNode.getRootNode()
    .children[0].name()
  console.log('fileName', fileName)
  const indexPoint = fileName.lastIndexOf('.')
  console.log('indexPoint', indexPoint)
  return fileName.substring(0, indexPoint)
}

const getFileExtensionModel = () => {
  return viewer?.model?.getData()?.loadOptions?.fileExt
}

const getData = async (properties) => {
  const dataRaw = await getDataAsync(properties)
  const data = dataRaw.map((obj) => {
    const { dbId, properties } = obj
    const nuevasProperties = properties.map((prop) => {
      const { displayName, displayCategory, displayValue } = prop
      return { displayName, displayCategory, displayValue }
    })
    return { dbId, properties: nuevasProperties }
  })
  console.log('data', data)

  return data
}

const Helpers = {
  launchViewer,
  getData,
  getPropertyNames,
  getAllPropertyNames,
  getModelFileName,
  getFileExtensionModel,
  getUrnViewer,
}

export default Helpers
