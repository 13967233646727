import React from 'react'
import { Button } from 'react-bootstrap'
import InfoIcon from '@mui/icons-material/Info'
const ORIGIN_URL = process.env.REACT_APP_AUTH0_LOGOUT_URL

const ButtonDocuActivateHub = () => {
  return (
    <a href={`${ORIGIN_URL}/docs/quickstart/activatehub`} target="_blank">
      <Button
        size="small"
        style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}
      >
        <InfoIcon />
        Activar HUB
      </Button>
    </a>
  )
}

export default ButtonDocuActivateHub
