import Col from 'react-bootstrap/esm/Col'
const LegalFooter = () => {
  return (
    <div
      className="row"
      style={{
        width: '99.9vw',
        paddingTop: '5px',
        overflow: 'hidden',
        backgroundColor: 'lightgrey',
        // position: 'fixed',
        // bottom: '0',
      }}
    >
      <Col xs={12} md={3} style={{ textAlign: 'center' }}>
        <p style={{ fontWeight: '600', fontSize: '1.3rem' }}>Privacidad</p>
      </Col>
      <Col xs={12} md={3} style={{ textAlign: 'center' }}>
        <p style={{ fontWeight: '600', fontSize: '1.3rem' }}>Cokies</p>
      </Col>
      <Col xs={12} md={3} style={{ textAlign: 'center' }}>
        <p style={{ fontWeight: '600', fontSize: '1.3rem' }}>
          Preferencias de uso
        </p>
      </Col>
      <Col xs={12} md={3} style={{ textAlign: 'center' }}>
        <p style={{ fontWeight: '600', fontSize: '1.3rem' }}>Copyright 2023</p>
      </Col>
    </div>
  )
}

export default LegalFooter
