import React, { useEffect } from 'react'
import { Icon, IconButton, Pagination, Stack } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

const PaginationFacturas = ({
  currentPage,
  setCurrentPage,
  nextPage,
  prevPage,
  setStartingAfterInvoice,
  setEndingBeforeInvoice,
  lastInvoice,
  setLoadingFacturas,
  firstInvoice,
}) => {
  useEffect(() => {
    console.log('NEXT PAGE: ', nextPage)
    console.log('PREV PAGE: ', prevPage)
  }, [])

  const iconButtonCurrentPageStyle = {
    backgroundColor: 'grey',
    color: 'white',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  }

  const iconButtonStyle = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  }

  const handleNextPage = () => {
    setLoadingFacturas()
    setEndingBeforeInvoice(null)
    setStartingAfterInvoice(lastInvoice)
    setCurrentPage(currentPage + 1)
  }

  const handlePrevPage = () => {
    setLoadingFacturas()
    setStartingAfterInvoice(null)
    setEndingBeforeInvoice(firstInvoice)
    setCurrentPage(currentPage - 1)
  }

  return (
    <div className="container-pagination">
      <Stack spacing={2}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          <ArrowBackIosIcon
            style={{ cursor: prevPage ? 'pointer' : null }}
            onClick={prevPage ? handlePrevPage : null}
          />
          <div style={{ display: 'flex', gap: '.4rem' }}>
            {/* SI HAY PREV PAGE RESTAMOS UNO A CURRENT PAGE */}
            {prevPage && (
              <IconButton style={iconButtonStyle} onClick={handlePrevPage}>
                {currentPage - 1}
              </IconButton>
            )}

            {/* PAGINA ACTUAL */}
            <IconButton style={iconButtonCurrentPageStyle}>
              {currentPage}
            </IconButton>

            {/* SI HAY NEXT PAGE SUMAMOS UNO A CURRENT PAGE */}
            {nextPage && (
              <IconButton style={iconButtonStyle} onClick={handleNextPage}>
                {currentPage + 1}
              </IconButton>
            )}
          </div>
          <ArrowForwardIosIcon
            style={{ cursor: nextPage ? 'pointer' : null }}
            onClick={nextPage ? handleNextPage : null}
          />
        </div>
      </Stack>
    </div>
  )
}

export default PaginationFacturas
