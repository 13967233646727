import Col from 'react-bootstrap/esm/Col'

const Footer = () => {
  return (
    <div
      className="row"
      style={{ margin: '0', overflow: 'hidden', padding: '25px' }}
    >
      <Col xs={12} md={4} style={{ textAlign: 'center' }}>
        <div className="brand-container">
          <div className="footer-logo-container">
            <img src="https://bimsualize.fra1.digitaloceanspaces.com/logo_bimsualize.png" />
          </div>
          <p className="footer-brand__bim">BIM</p>
          <p className="footer-brand__visualice">sualize</p>
        </div>
        {/* <img src="./logo192.png" alt="logo" /> */}
        <Col
          xs={12}
          md={3}
          lg={3}
          style={{ textAlign: 'center', borderRight: 'solid 1px  black ' }}
        >
          <div
            className="glyph-icon simple-icon-social-linkedin"
            style={{ fontSize: '2.5rem', cursor: 'pointer' }}
          />
        </Col>
        <Col
          xs={3}
          md={3}
          lg={3}
          style={{ textAlign: 'center', borderRight: 'solid 1px  black ' }}
        >
          <div
            className="glyph-icon simple-icon-social-facebook"
            style={{ fontSize: '2.5rem', cursor: 'pointer' }}
          />
        </Col>
        <Col
          xs={3}
          md={3}
          lg={3}
          style={{ textAlign: 'center', borderRight: 'solid 1px  black ' }}
        >
          <div
            className="glyph-icon simple-icon-social-twitter"
            style={{ fontSize: '2.5rem', cursor: 'pointer' }}
          />
        </Col>
        <Col
          xs={3}
          md={3}
          lg={3}
          style={{ textAlign: 'center', borderRight: 'solid 1px  black ' }}
        >
          <div
            className="glyph-icon simple-icon-social-instagram"
            style={{ fontSize: '2.5rem', cursor: 'pointer' }}
          />
        </Col>
      </Col>
      <Col
        xs={3}
        md={3}
        style={{
          marginLeft: '50px',
          borderLeft: 'solid 1px  black ',
          padding: '20px',
        }}
      >
        <p
          style={{
            fontSize: '2rem',
            fontWeight: '700',
            fontFamily: 'Lato',
          }}
        >
          SERVICIOS
        </p>
        <ul>
          <p href="#">BIMsualize para ...</p>
        </ul>
        <ul>
          {' '}
          <a href="#">Recursos Power Bi</a>
        </ul>
        <ul>
          <a href="#">Servicios ACC</a>
        </ul>
      </Col>
      <Col
        xs={12}
        md={3}
        style={{ borderLeft: 'solid 1px  black ', padding: '20px' }}
      >
        <div>
          <p
            style={{
              fontSize: '2rem',
              fontWeight: '700',
              fontFamily: 'Lato',
            }}
          >
            CONTACTO
          </p>
          <p>info@atbim.es</p>
          <p>(+34)660514455</p>
          <p>Avenida Ranillas 3C, Oficina B, 50018 Zaragoza</p>
        </div>
      </Col>
    </div>
  )
}

export default Footer
