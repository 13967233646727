import React from 'react'
import ContentLoader from 'react-content-loader'

const PbivizSkeleton = (props) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <ContentLoader
      width={450}
      height={470}
      viewBox="0 0 450 400"
      backgroundColor="white"
      foregroundColor="#ecebeb"
      {...props}
    >
      <rect x="111" y="77" rx="10" ry="10" width="250" height="217" />
      <rect x="112" y="304" rx="4" ry="4" width="241" height="9" />
      <rect x="113" y="323" rx="3" ry="3" width="89" height="6" />
    </ContentLoader>
  </div>
)

export default PbivizSkeleton
