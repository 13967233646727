import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ChipForm from './components/ChipForm'
import NameInput from './components/NameInput'
import { createAuthorization } from '../../api/latamApi'
import ModalAuthorizeCode from './ModalAuthorizeCode'
import { useQueryClient } from '@tanstack/react-query'

const ModalContainerAuthorize = (props) => {
  const { onHide, show } = props
  const [name, setName] = useState('')
  const [emails, setEmails] = useState([])
  const [code, setCode] = useState(null)
  const [isOpenModalCode, setIsOpenModalCode] = useState(false)
  const queryClient = useQueryClient()

  const isValidForm = () => {
    return name.trim().length > 0
  }

  const handleAuthorizeClick = async () => {
    try {
      let body
      if (emails.length > 0) {
        body = { name, guests: emails }
      } else {
        body = { name }
      }

      const { data } = await createAuthorization(body)
      setCode(data.code)
      queryClient.invalidateQueries(['authorizations'])
      setIsOpenModalCode(true)
      toast.success('Autorización creada.')
    } catch (e) {
      console.log(e)
      toast.error(
        'Error al obtener el código de autorización. Vuelve a intentarlo.'
      )
    }
  }

  const closeOffCanvas = () => {
    setName('')
    setEmails([])
    setCode([])
    onHide()
  }

  const closeAllPanels = () => {
    setIsOpenModalCode(false)
    closeOffCanvas()
  }

  useEffect(() => {
    if (!show) {
      closeOffCanvas()
    }
  }, [show])

  return (
    <>
      <ModalAuthorizeCode
        show={isOpenModalCode}
        onHide={closeAllPanels}
        code={code}
        name={name}
      />
      <Offcanvas {...props}>
        <div
          style={{ display: 'flex', flexDirection: 'column', height: '100%' }}
        >
          <Offcanvas.Header>
            <Offcanvas.Title>Nueva autorización</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <NameInput name={name} setName={setName} />
            <ChipForm emails={emails} setEmails={setEmails} />
          </Offcanvas.Body>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '1rem',
              padding: '10px',
            }}
          >
            <Button variant="secondary" onClick={closeOffCanvas}>
              Cancelar
            </Button>

            <Button
              variant="primary"
              disabled={!isValidForm()}
              onClick={handleAuthorizeClick}
            >
              Autorizar
            </Button>
          </div>
        </div>
      </Offcanvas>
    </>
  )
}

export default ModalContainerAuthorize
