import axios from 'axios'
import { useEffect, useState } from 'react'
// const ALLOWED_COUNTRIES = process.env.REACT_APP_LATAM_COUNTRIES || []
// const ALLOWED_POSTAL = process.env.REACT_APP_INCLUDE_POSTAL || []
const useUserLocation = () => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isUserAllowed, setIsUserAllowed] = useState(false)
  useEffect(() => {
    setIsError(false)
    setIsLoading(true)
    axios
      .get('https://ipapi.co/json/')
      .then((res) => {
        const { data } = res
        const { country, postal } = data
        setData(data)
        axios
          .get('/api/v1/env')
          .then((res2) => {
            const { data } = res2
            const ALLOWED_COUNTRIES =
              data?.data?.REACT_APP_LATAM_COUNTRIES || null
            const ALLOWED_POSTAL = data?.data?.REACT_APP_INCLUDE_POSTAL || null
            setIsUserAllowed(
              ALLOWED_COUNTRIES.split(',').includes(country) ||
                ALLOWED_POSTAL.split(',').includes(postal)
            )
            setIsLoading(false)
          })
          .catch((e) => setIsError(true))
      })
      .catch((e) => setIsError(true))
  }, [])

  return { data, isUserAllowed, isLoading, isError }
}

export default useUserLocation
