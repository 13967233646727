import { Box, CircularProgress } from "@mui/material";

const GlobalSpinner = () => {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
        }}>
            <CircularProgress />
        </Box>
    )
};

export default GlobalSpinner;