import Helpers from '../components/Viewer/Viewer-helpers'
import { colorFileExtension } from '../constants'
import { decodeBase64 } from './base64utils'

export const getFileExtensionColors = (extension) => {
  return colorFileExtension[extension]
    ? colorFileExtension[extension]
    : colorFileExtension.default
}

export const getVersionByUrn = () => {
  const urn = Helpers.getUrnViewer()
  const urnDecode = decodeBase64(urn)

  // Dividir la cadena por "="
  const parts = urnDecode.split('=')
  // Obtener la última parte
  const lastPart = parts[parts.length - 1]

  return lastPart
}

export const sortSettingsByFileExtension = (settings, extension) => {
  let newSettings = settings

  if (extension == 'nwc' || extension == 'nwd') {
    newSettings = newSettings.map((setting) => {
      if (setting.extension == 'nwc' || setting.extension == 'nwd') {
        console.log('entra aquiiiii', extension)
        return {
          ...setting,
          extension,
        }
      }
      return setting
    })
  }

  newSettings.sort(function (a, b) {
    if (a.extension === extension && b.extension !== extension) {
      return -1
    }
    if (a.extension !== extension && b.extension === extension) {
      return 1
    }
    return 0
  })
  console.log(newSettings)
  return newSettings
}

export const checkFileExtension = (fileExtensionModel, extension) => {
  if (fileExtensionModel == extension) return true
  if (fileExtensionModel == 'nwc' || fileExtensionModel == 'nwd') {
    if (extension == 'nwc' || extension == 'nwd') return true
  }
  return false
}
