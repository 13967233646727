import { IconButton } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'

const NavbarItemSettingPage = ({ item }) => {
  console.log(item)
  return (
    <NavLink to={`/settings${item.href}`} className="navlink-aside-item">
      <IconButton className="sidebar-item-button">
        <item.icon />
        <span className="sidebar-item-label">{item.label}</span>
      </IconButton>
    </NavLink>
  )
}

export default NavbarItemSettingPage
