import React, { useEffect, useMemo, useState } from 'react'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Button from 'react-bootstrap/Button'
import Accordion from 'react-bootstrap/Accordion'
import ListGroup from 'react-bootstrap/ListGroup'
import Checkbox from '@mui/material/Checkbox'
import { toast } from 'react-toastify'
import ModalNameSettings from './ModalNameSettings'

import SearchProperties from './SearchProperties'
import { sortCategoryProperties } from '../../utils/sortProperties'
import PaginationProperties from '../Pagination/PaginationProperties'

const initialState = {
  page: 0,
  rowsPerPage: 20,
}

const ModalProperties = (props) => {
  const [selectedProperties, setSelectedProperties] = useState([])
  const [page, setPage] = useState(initialState.page)
  const [rowsPerPage, setRowsPerPage] = useState(initialState.rowsPerPage)
  const [valueInput, setValueInput] = useState('')
  const [isAddNameSettings, setIsAddNameSettings] = useState(false)
  const { properties } = props
  const [propertiesFiltered, setPropertiesFiltered] = useState(null)
  const handleOnChange = (property, e) => {
    const key = `${property.cat}-${property.name}`
    if (e.target.checked) {
      setSelectedProperties((previousSate) => [
        ...previousSate,
        { key, property, category: property.cat },
      ])
    } else {
      setSelectedProperties((previousSate) =>
        previousSate.filter((item) => item.key !== key)
      )
    }
  }

  const closeModal = () => {
    setPage(initialState.page)
    setRowsPerPage(initialState.rowsPerPage)
    setSelectedProperties([])
    props.onHide()
  }

  const handleSaveSettings = () => {
    console.log('selectedProperties', selectedProperties)
    if (selectedProperties.length == 0) {
      return toast.error('Tienes que marcar, al menos, una propiedad')
    } else {
      setIsAddNameSettings(true)
    }
  }

  useEffect(() => {
    if (!props.show) {
      setSelectedProperties([])
    }
  }, [props.show])

  useEffect(() => {
    console.log('propertiesFiltered', propertiesFiltered)
    if (valueInput.length >= 3) {
      const filteredProperties = Object.keys(properties).reduce(
        (filtered, category) => {
          const categoryProperties = properties[category].filter((property) =>
            property.name.toLowerCase().startsWith(valueInput.toLowerCase())
          )

          if (categoryProperties.length > 0) {
            filtered[category] = categoryProperties
          }

          return filtered
        },
        {}
      )

      setPropertiesFiltered(filteredProperties)
    } else {
      setPropertiesFiltered(null)
    }
  }, [valueInput, properties])

  useEffect(() => {
    console.log('VAMOS A VER LAS PROPERTIES QUE ESTAMOS TRAYENDO: ', properties)
  }, [])

  return (
    <>
      <ModalNameSettings
        show={isAddNameSettings}
        handleClose={() => setIsAddNameSettings(false)}
        selectedProperties={selectedProperties}
        closeModalProperties={() => props.onHide()}
      />
      <Offcanvas {...props}>
        <div className="offcanvas-properties-container">
          <Offcanvas.Header>
            <Offcanvas.Title>
              Configuración de propiedades a Exportar
            </Offcanvas.Title>
          </Offcanvas.Header>

          <Offcanvas.Body className="offcanvas-properties-body">
            <SearchProperties
              valueInput={valueInput}
              setValueInput={setValueInput}
            />
            {/* <PaginationProperties /> */}
            <div
              className="properties-container"
              // style={{ maxHeight: '90%', overflowY: 'auto' }}
            >
              {properties &&
                !propertiesFiltered &&
                sortCategoryProperties(Object.keys(properties))
                  .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                  .map((category, index) => (
                    <Accordion key={index} style={{ fontSize: '12px' }}>
                      <Accordion.Header className="properties-category-name">
                        <div
                          style={{
                            display: 'flex',
                            gap: '1rem',
                            alignItems: 'center',
                          }}
                        >
                          {selectedProperties.filter(
                            (item) => item.category === category
                          ).length > 0 && (
                            <span className="number-items-selected">
                              {/* AQUI VA A IR EL NUMER DE VECES QUE APARECE ESTA CATEGORIA EN EL ARRAY DE SELECTEDPROPERTIES */}
                              {
                                selectedProperties.filter(
                                  (item) => item.category === category
                                ).length
                              }
                            </span>
                          )}
                          {category}
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="offcanvas-properties-body">
                        <ListGroup>
                          {properties[category].map((property, index) => (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '1rem',
                                marginBottom: '.4rem',
                                alignItems: 'center',
                              }}
                              key={index}
                            >
                              <Checkbox
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                                onChange={(e) => handleOnChange(property, e)}
                                checked={
                                  selectedProperties.filter(
                                    (item) =>
                                      item.key ===
                                      `${category}-${property.name}`
                                  ).length > 0
                                }
                              />
                              <ListGroup.Item key={index} style={{ flex: 1 }}>
                                {property.name}
                              </ListGroup.Item>
                            </div>
                          ))}
                        </ListGroup>
                      </Accordion.Body>
                    </Accordion>
                  ))}
              {properties &&
                propertiesFiltered &&
                Object.keys(propertiesFiltered).map((category, index) => (
                  <Accordion key={index} style={{ fontSize: '12px' }}>
                    <Accordion.Header className="properties-category-name">
                      <div
                        style={{
                          display: 'flex',
                          gap: '1rem',
                          alignItems: 'center',
                        }}
                      >
                        {selectedProperties.filter(
                          (item) => item.category === category
                        ).length > 0 && (
                          <span className="number-items-selected">
                            {/* AQUI VA A IR EL NUMER DE VECES QUE APARECE ESTA CATEGORIA EN EL ARRAY DE SELECTEDPROPERTIES */}
                            {
                              selectedProperties.filter(
                                (item) => item.category === category
                              ).length
                            }
                          </span>
                        )}
                        {category}
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <ListGroup>
                        {propertiesFiltered[category].map((property, index) => (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              gap: '1rem',
                              marginBottom: '.4rem',
                              alignItems: 'center',
                            }}
                            key={index}
                          >
                            <Checkbox
                              sx={{ '& .MuiSvgIcon-root': { fontSize: 22 } }}
                              onChange={(e) => handleOnChange(property, e)}
                              checked={
                                selectedProperties.filter(
                                  (item) =>
                                    item.key === `${category}-${property.name}`
                                ).length > 0
                              }
                            />
                            <ListGroup.Item key={index} style={{ flex: 1 }}>
                              {property.name}
                            </ListGroup.Item>
                          </div>
                        ))}
                      </ListGroup>
                    </Accordion.Body>
                  </Accordion>
                ))}
            </div>
          </Offcanvas.Body>
          <div className="offcanvas-properties-footer">
            <PaginationProperties
              propertiesLength={Object.keys(properties).length}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '1rem',
                marginRight: '.5rem',
              }}
            >
              <Button variant="secondary" onClick={closeModal}>
                Cancelar
              </Button>{' '}
              <Button variant="primary" onClick={handleSaveSettings}>
                {' '}
                Guardar configuración
              </Button>{' '}
            </div>
          </div>
        </div>
      </Offcanvas>
    </>
  )
}

export default ModalProperties
