import { Card } from 'react-bootstrap'

const HomeCard = ({ data }) => {
  return (
    <Card style={{ width: '100%', height: '450px', padding: '10px' }}>
      <div style={{ height: '200px' }}>
        {data.img ? (
          // <Card.Img
          //   variant="top"
          //   src={data.img}
          //   style={{
          //     borderRadius: '5px',
          //     // height: '100px',
          //     backgroundSize: 'contain',
          //   }}
          // />
          <img
            src={data.img}
            alt={data.title}
            style={{
              borderRadius: '5px',
              objectFit: 'cover',
              height: '200px',
              width: '100%',
            }}
          />
        ) : (
          <div
            className={'glyph-icon ' + data.icon}
            style={{
              fontSize: '10rem',
              textAlign: 'center',
            }}
          />
        )}
      </div>
      <Card.Body
      // style={{ position: 'absolute', top: '50%', left: '33%' }}
      >
        {/* <Button variant="primary">Go somewhere</Button> */}

        <div style={{ minHeight: '40%' }}>
          <Card.Title
            style={{
              fontSize: '2.2rem',
              fontWeight: '800',
              minHeight: '30%',
            }}
          >
            {data.title}
          </Card.Title>
          <div
            style={{
              height: '1px',
              backgroundColor: 'black',
              margin: '10px',
            }}
          />
          <Card.Text style={{ fontSize: '1.5rem', fontWeight: '600' }}>
            {data.detail}
          </Card.Text>
        </div>
      </Card.Body>
    </Card>
  )
}

export default HomeCard
