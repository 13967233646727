import { _instances } from '../components/Viewer/Viewer-helpers'
import { getUniqueElements } from './compareArrays'

export const downloadJsonFile = (data, filename) => {
  console.log('entra aquiiii')
  console.log('data', data)
  const jsonStr = JSON.stringify(data)
  const blob = new Blob([jsonStr], { type: 'application/json' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.download = `${filename}.json`
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const handleDataToDownloadRvt = (data, urn, guid) => {
  console.log('urn')
  const properties = data.map((property) => {
    const { properties, dbId, ...rest } = property
    let newObject = {}
    const formatProperties = properties.map(
      ({ displayCategory, displayName, displayValue }) => ({
        [`${displayName}__${displayCategory}`]: displayValue,
      })
    )

    formatProperties.forEach((item) => {
      newObject = Object.assign(newObject, item)
    })

    return { dbId, properties: newObject }
  })
  console.log('_instances', _instances)
  const notDbIsInProperties = getUniqueElements(
    _instances,
    properties.map((prop) => prop.dbId)
  )

  console.log('notDbIdsInProperties', notDbIsInProperties)
  const handledNotDbIdsInproperties = notDbIsInProperties.map((dbId) => ({
    dbId,
    properties: {},
  }))
  console.log('properties', properties)

  if (guid) {
    return {
      urn,
      vista: guid,
      data: [...properties, ...handledNotDbIdsInproperties],
    }
  }

  return {
    urn,
    data: [...properties, ...handledNotDbIdsInproperties],
  }
}

export const handleDataToDownloadNwd = (data, urn, guid) => {
  if (guid) {
    return {
      urn,
      vista: guid,
      data,
    }
  }
  return {
    urn,
    data,
  }
}
