import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'
import './styles/index.scss'
import { Auth0ProviderWithConfig } from './auth0-config/auth0-provider-config'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

// replace console.* for disable log on production
if (process.env.REACT_APP_NODE_ENV == 'production') {
  console.log = () => {}
  console.debug = () => {}
  console.info = () => {}
  console.warn = () => {}
  //   // console.error = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root'))
// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
})
root.render(
  <QueryClientProvider client={queryClient}>
    <Auth0ProviderWithConfig>
      <ToastContainer
        position="top-right"
        autoClose={4000}
        closeOnClick
        hideProgressBar={true}
        pauseOnFocusLoss={false}
      />
      <App />
    </Auth0ProviderWithConfig>
    {/* <ReactQueryDevtools initialIsOpen={false} /> */}
  </QueryClientProvider>
)
