import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Menu from '@mui/material/Menu'
import { NavLink, useNavigate } from 'react-router-dom'
import SettingsIcon from '@mui/icons-material/Settings'
import LogoutIcon from '@mui/icons-material/Logout'
import DropdownMenuItem from './components/DropdownMenuItem'
import { UserContext } from '../../context/UserContext'

const DropdownUserLogin = ({ trialEnd }) => {
  const { user } = useAuth0()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const { logout } = useAuth0()
  const { user: userAutodesk } = useContext(UserContext)
  const redirectLogout = process.env.REACT_APP_AUTH0_LOGOUT_URL
  const options = useRef([
    {
      id: 'ajustes',
      text: 'Ajustes',
      Icon: SettingsIcon,
      onClick: () => navigate('/settings'),
    },
    {
      id: 'desconectarse',
      text: 'Desconectarse',
      Icon: LogoutIcon,
      onClick: () =>
        logout({
          logoutParams: { returnTo: redirectLogout },
        }),
    },
  ])

  useEffect(() => {
    console.log('USER AUTODESK', userAutodesk)
  }, [userAutodesk])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {/* PERIODO DE PRUEBA */}
      {trialEnd && !userAutodesk?.isPremium && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            marginRight: '1rem',
          }}
        >
          <p style={{ margin: '0' }}>Quedan {trialEnd} días de prueba</p>
          <NavLink to="/settings">
            <div
              style={{
                backgroundColor: '#f1bd6c',
                fontSize: '12px',
                height: '28px',
                lineHeight: '28px',
                padding: '0 8px',
                cursor: 'pointer',
                alignItems: 'center',
                border: '1px solid',
                borderColor: '#f1bd6c',

                borderRadius: '6px',
                boxSizing: 'border-box',
                display: 'inline-flex',
                flexShrink: '0',
                overflow: 'hidden',
                transitionDuration: '.2s',
                transitionProperty: 'background, border, color, fill',
                userSelect: 'none',
              }}
            >
              Actualizar
            </div>
          </NavLink>
        </div>
      )}
      <Button
        style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="dropdown-menu-login"
      >
        <img
          style={{
            width: '40px',
            height: '40px',
            borderRadius: '40px',
          }}
          src={user.picture}
          referrerPolicy="no-referrer"
          alt="user-profile"
        />
        <span
          style={{
            color: 'black',
            textTransform: 'initial',
            fontSize: '1.2rem',
          }}
        >
          {user.email}
        </span>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {options.current.map((option, i) => (
          <DropdownMenuItem
            key={option.id}
            Icon={option.Icon}
            text={option.text}
            onClick={option.onClick}
            isLast={i == options.current.length - 1 ? true : false}
          />
        ))}
      </Menu>
    </div>
  )
}

export default DropdownUserLogin
