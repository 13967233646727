import React, { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import ModalProperties from '../ModalProperties/ModalProperties'
import Helpers, { allBubbleNodes, viewer } from './Viewer-helpers'
import Spinner from 'react-bootstrap/Spinner'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Viewer from './Viewer'
import { ViewerContext } from '../../context/ViewerContext'
import ModalDownloadData from '../ModalDownloadData/ModalDownloadData'
import ModalManageSettings from '../ModalManageSettings/ModalManageSettings'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { Menu, MenuItem } from '@mui/material'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { getAllViewablesFromDoc } from '../../utils/BubbleNodes'
import ViewsButton from './ViewsButton.js'

const ViewerContainer = ({
  urn,
  versionName,
  versionArray,
  setUrn,
  setVersionName,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [showModalDownloadJson, setShowModalDownloadJson] = useState(false)
  const [showModalManageSettings, setShowModalManageSettings] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [properties, setProperties] = useState([])
  const [showTestingButton, setShowTestingButotn] = useState(false)
  const {
    areViewerDbIdsLoaded,
    setAreViewerDbIdsLoaded,
    setAllPropertyNames,
    allPropertyNames,
  } = useContext(ViewerContext)
  const handleClose = () => setShowModal(false)

  const handlePropertiesClick = async () => {
    // const propertyNames = await Helpers.getAllPropertyNames()
    const propertyNames = await Helpers.getPropertyNames()
    // console.log('propertynames handlePropertiesclick', propertyNames)
    setProperties(propertyNames)
    setShowModal(true)
  }

  const handleManageProperties = () => {
    setShowModalManageSettings(true)
  }

  const testintViewables = () => {
    const allViewables = getAllViewablesFromDoc(allBubbleNodes)

    // const viewableToLoad = allViewables[0].bubbleNode0[10].getViewableRootPath()
    console.log('allViewables', allViewables)

    // console.log(allViewables[0].bubbleNode0[10].guid())
    // console.log('viewableToload', viewableToLoad)
    // viewer.tearDown()
    // viewer.start()
    // viewer.setView(allViewables[0][2])
  }

  // useEffect(() => {
  //   const allViewables = getAllViewablesFromDoc(allBubbleNodes)
  //   setViews(allViewables[0])
  // }, [])

  return (
    urn && (
      <>
        {/* MENU PARA ELEGIR UNA DE LAS VERSIONES */}
        <Menu
          style={{ maxHeight: '500px', marginTop: '1rem' }}
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setAnchorEl(null)}
        >
          {versionArray.map((version, index) => {
            let nameVersionMenu =
              'v' +
              version.attributes.versionNumber +
              ' - ' +
              version.attributes.createTime.split('T')[0]

            let nameVersionItem =
              'v' +
              version.attributes.versionNumber +
              ' - ' +
              version.attributes.name

            return (
              <MenuItem
                style={{ fontSize: '12px' }}
                key={version.id}
                onClick={() => {
                  setVersionName(nameVersionItem)
                  setUrn(version.id)
                  setAnchorEl(null)
                }}
              >
                {nameVersionMenu}
              </MenuItem>
            )
          })}
        </Menu>

        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
          }}
          className="h-100"
        >
          <div
            style={{
              // height: '15%',
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '2rem 2rem .5rem ',
            }}
          >
            <div
              style={{ display: 'flex', alignItems: 'center', gap: '.6rem' }}
            >
              <KeyboardArrowDownIcon
                onClick={(e) => setAnchorEl(e.currentTarget)}
                style={{ cursor: 'pointer' }}
                fontSize="large"
              />
              <span style={{ fontSize: '24px', whiteSpace: 'nowrap' }}>
                {versionName}
              </span>
            </div>
            {areViewerDbIdsLoaded ? (
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                }}
              >
                {/* <ViewsButton /> */}
                <DropdownButton
                  id="dropdown-basic-button"
                  title="Gestionar configuraciones de propiedades"
                >
                  <Dropdown.Item
                    onClick={handlePropertiesClick}
                    style={{
                      padding: '.5rem .5rem',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                    disabled={!allPropertyNames}
                    className={`${
                      !allPropertyNames && 'disabled-dropdown-item'
                    }`}
                  >
                    Nueva configuración {!allPropertyNames && <Spinner />}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={handleManageProperties}
                    style={{ padding: '.5rem .5rem' }}
                  >
                    Administrar configuraciones
                  </Dropdown.Item>
                </DropdownButton>
                <Button onClick={() => setShowModalDownloadJson(true)}>
                  Descargar Datos
                </Button>
              </div>
            ) : (
              <Spinner />
            )}
          </div>
          <div style={{ flex: '1', padding: '2rem', position: "relative" }}>
            <Viewer
              urn={urn}
              setAreViewerDbIdsLoaded={setAreViewerDbIdsLoaded}
              setAllPropertyNames={setAllPropertyNames}
            />
            <ViewsButton />
          </div>
          <ModalManageSettings
            scroll={true}
            show={showModalManageSettings}
            onHide={() => setShowModalManageSettings(false)}
          />
          <ModalDownloadData
            scroll={true}
            show={showModalDownloadJson}
            onHide={() => setShowModalDownloadJson(false)}
          />
          <ModalProperties
            scroll={true}
            show={showModal}
            onHide={handleClose}
            properties={properties}
          />
        </div>
      </>
    )
  )
}

export default ViewerContainer
