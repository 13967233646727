import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'

const NameInput = ({ name, setName }) => {
  const handleOnChange = (e) => {
    setName(e.target.value)
  }

  return (
    <InputGroup
      size="sm"
      className="mb-3"
      style={{ display: 'flex', flexDirection: 'row' }}
    >
      <Form.Label>Nombre *</Form.Label>
      <Form.Control
        aria-label="Small"
        aria-describedby="inputGroup-sizing-sm"
        value={name}
        onChange={handleOnChange}
        placeholder="Escribe un nombre para la autorización"
        required
      />
    </InputGroup>
  )
}

export default NameInput
