export const sortCategoryProperties = (data) => {
  return data.sort(function (a, b) {
    // Comprobar si ambos elementos comienzan con símbolos
    const isAStartingWithSymbol = /^\W/.test(a)
    const isBStartingWithSymbol = /^\W/.test(b)

    if (isAStartingWithSymbol && !isBStartingWithSymbol) {
      return -1 // a viene primero
    } else if (!isAStartingWithSymbol && isBStartingWithSymbol) {
      return 1 // b viene primero
    } else {
      // Orden alfabético para los elementos que no comienzan con símbolos
      return a.localeCompare(b)
    }
  })
}
