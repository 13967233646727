import Spinner from 'react-bootstrap/Spinner'

const LoaderDownloadData = () => {
    return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
          <p style={{ fontStyle: 'oblique' }}>Descargando datos...</p>
        </div>)
}

export default LoaderDownloadData