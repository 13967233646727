import { Button } from '@mui/material'
import axios from 'axios'
import React, { useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify'
import useUserLocation from '../hooks/useUserLocation'
import Spinner from 'react-bootstrap/Spinner'

const SimpleSpinner = () => (
  <Spinner animation="border" role="status">
    <span className="visually-hidden">Loading...</span>
  </Spinner>
)

const LoginButton = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  const { isUserAllowed, isLoading } = useUserLocation()

  const checkRegion = () => {
    if (isUserAllowed) {
      return loginWithRedirect()
    } else {
      return toast.error(
        'Lo sentimos, esta aplicación todavía no está disponible en tu región.'
      )
    }
  }

  // const checkRegion = () => {
  //     (async () => {
  //         let myCountry = await axios.get('https://ipapi.co/json/')
  //         console.log('MY COUNTRY: ', myCountry.data.country)
  //         if (!isAuthenticated && process.env.REACT_APP_COUNTRYS_LATAM.includes(myCountry.data.country)) {
  //             await loginWithRedirect()
  //         } else {
  //             return toast.error('Lo sentimos, esta aplicación todavía no está disponible en tu región.')
  //         }
  //     })();

  // }

  return (
    <Button
      variant="contained"
      className="login-btn"
      style={{ marginRight: '1rem' }}
      onClick={checkRegion}
      disabled={isLoading}
    >
      {!isLoading ? 'Iniciar Sesión' : <SimpleSpinner />}
    </Button>
  )
}

export default LoginButton
