import React from 'react'

const LoginAutodesk = () => {
  const handleLoginClick = () => {
    window.location.replace('/api/auth/login')
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column',
        paddingTop: '5rem',
        gap: '1rem',
      }}
    >
      <span>Para poder utilizar la aplicación inicie sesión en Autodesk</span>
      <button onClick={handleLoginClick} className="btn btn-secondary">
        <img
          src="https://atbim.fra1.cdn.digitaloceanspaces.com/symbol-1.svg"
          height="25px"
        />
        Conectarse
      </button>
    </div>
  )
}

export default LoginAutodesk
