import React, { createContext, useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import {
  getFreeTrialSubscription,
  getProfileAutodesk,
  getSubscriptions,
  getUserByAuth0Id,
  getUserStripe,
} from '../api/latamApi'
import axios from 'axios'
import { calculateDaysDifference } from '../helpers/stripeHelper'
import { CircularProgress } from '@mui/material'

export const UserContext = createContext(null)

export const UserProvider = ({ children }) => {
  const [userAutodesk, setUserAutodesk] = useState(false)
  const [isLoadingAutodeskUser, setIsLoadingAutodeskUser] = useState(true)
  const [areCorrectCredentials, setAreCorrectCredentials] = useState(false)
  const [subscription, setSubscription] = useState(null)
  const [primerRenderizado, setPrimerRenderizado] = useState(true)
  const [expiresSubscription, setExpiresSubscription] = useState(null)

  const [endTrial, setEndTrial] = useState(null)
  const [activeCurrentSubscription, setActiveCurrentSubscription] =
    useState(null)
  const [loadingSubscription, setLoadingSubscription] = useState(true)

  const { user: userAuth0, getAccessTokenSilently } = useAuth0()

  const {
    data: user,
    isLoading,
    error,
    refetch,
  } = useQuery(
    ['user', userAuth0?.sub],
    () => getUserByAuth0Id(userAuth0?.sub),
    {
      enabled: !!userAuth0?.sub,
    }
  )

  const getAccessToken = async () => {
    const access_token = await getAccessTokenSilently()
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
    return access_token
  }

  useEffect(() => {
    console.log('testeo de si se hace el deploy')
    if (primerRenderizado) {
      setPrimerRenderizado(false)
    } else {
      // if (userAuth0) {
      //   ;(async () => {
      //     await getAccessToken()
      //     await checkProfileAutodesk()
      //   })()
      // } else {
      //   setLoadingSubscription(false)
      // }
    }
    // En el momento en el que se obtenga la información del user de Auth0, establecer en las cabecera de la petición el token como autorización
  }, [userAuth0, primerRenderizado])

  useEffect(() => {
    if (userAuth0) {
      console.log('entra aquiiii', userAuth0)
      ;(async () => {
        await getAccessToken()
        await checkProfileAutodesk()
      })()
    } else {
      setLoadingSubscription(false)
    }
  }, [userAuth0])

  const checkProfileAutodesk = async () => {
    setIsLoadingAutodeskUser(true)
    try {
      const user = await getProfileAutodesk()
      console.log('usuario autodesk', user)
      setUserAutodesk(user)
      setIsLoadingAutodeskUser(false)
    } catch (e) {
      console.log('e', e)
      setUserAutodesk(false)
      setIsLoadingAutodeskUser(false)
    }
  }

  useEffect(() => {
    if (user) {
      const data = { email: user?.data?.email }
      fetch('/api/auth/user', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })

      // Sacar stripeUser
      getUserStripe(user?.data?.email).then((res) => console.log(res))
    } else {
    }
  }, [user])

  useEffect(() => {
    // Comprobar si el usuario de Auth0 y el de Autodesk es el mismo
    console.log('user', user)
    console.log('userAutodesk', userAutodesk)
    if (user && userAutodesk) {
      console.log('entra en existe user y userAutodesk')
      if (
        user?.data?.email.toLowerCase() == userAutodesk?.email.toLowerCase()
      ) {
        console.log('Los credenciales son correctos')
        setAreCorrectCredentials(true)
      } else {
        console.log('Los credenciales son incorrectos')
        setAreCorrectCredentials(false)
      }
    }
  }, [user, userAutodesk])

  // Sacar las suscripciones por usuario
  useEffect(() => {
    if (user) {
      getSubscriptions()
        .then((res) => {
          setSubscription(res.data.data.length > 0 ? res.data.data : [])
          setLoadingSubscription(false)
        })
        .catch((e) => setSubscription([]))
    }
  }, [user])

  const refetchSubscription = () => {
    getSubscriptions()
      .then((res) => {
        setSubscription(res.data.data.length > 0 ? res.data.data : [])
      })
      .catch((e) => setSubscription([]))
  }

  useEffect(() => {
    if (subscription) {
      setLoadingSubscription(true)
      subscription.map((sub) => {
        console.log('sub', sub)
        if (sub.status === 'trialing') {
          setEndTrial(calculateDaysDifference(sub.trial_end))
        }
        if (sub.status === 'active' && sub.cancel_at_period_end === false) {
          setActiveCurrentSubscription(sub)
        }
        if (sub.status === 'active' && sub.cancel_at_period_end === true) {
          setExpiresSubscription(sub.current_period_end)
        }
      })
      setLoadingSubscription(false)
    }
  }, [subscription])

  const data = {
    user: user?.data,
    subscription: subscription,
    isLoading,
    error,
    userAutodesk,
    isLoadingAutodeskUser,
    areCorrectCredentials,
    userAutodesk: userAutodesk,
    refetchSubscription,
    endTrial,
    activeCurrentSubscription,
    setActiveCurrentSubscription,
    loadingSubscription,
    expiresSubscription,
  }

  return (
    <UserContext.Provider value={data}>
      {loadingSubscription ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        children
      )}
    </UserContext.Provider>
  )
}
