export const manipulateDataToPaintSettingProperties = (setting) => {
  const arraySetting = [
    ...new Set(setting.propiedades.map((property) => property.cat)),
  ]
  let modifiedSettingProperties = arraySetting.reduce(function (obj, valor) {
    obj[valor] = []
    return obj
  }, {})

  setting.propiedades.forEach(({ name, cat }) => {
    modifiedSettingProperties[cat].push(name)
  })

  return modifiedSettingProperties
}

export const manipulateDataToPaintSelectedProperties = (selectedProperties) => {
  const arrayProperties = [
    ...new Set(selectedProperties.map((property) => property.property.cat)),
  ]
  let modifiedSelectedProperties = arrayProperties.reduce(function (obj, valor) {
    obj[valor] = []
    return obj
  }, {})

  selectedProperties.forEach(({ property }) => {
    const { name, cat } = property
    modifiedSelectedProperties[cat].push(name)
  })

  console.log('modifiedSelectedProperties', modifiedSelectedProperties)
  return modifiedSelectedProperties
}