import React, { useState } from 'react'
import ModalContainerAuthorize from './ModalContainerAuthorize'
import DropdownButton from 'react-bootstrap/esm/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import ModalManageAuthorizationContainer from './ModalManageAuthorizationContainer'


const AuthorizeButton = () => {
  const [isModalAuthorizeOpened, setIsModalAuthorizeOpened] = useState(false)
  const [isModalManageAuthorizeOpened, setIsModalManageAuthorizeOpened] =
    useState(false)
  return (
    <>
      <ModalContainerAuthorize
        show={isModalAuthorizeOpened}
        onHide={() => setIsModalAuthorizeOpened(false)}
      />
        <ModalManageAuthorizationContainer
          show={isModalManageAuthorizeOpened}
          onHide={() => setIsModalManageAuthorizeOpened(false)}
        />
      <DropdownButton id="dropdown-basic-button" title="Autorizar">
        <Dropdown.Item
          onClick={() => setIsModalAuthorizeOpened(true)}
          style={{
            padding: '.5rem .5rem',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          Nueva autorización{' '}
        </Dropdown.Item>
        <Dropdown.Item
          style={{ padding: '.5rem .5rem' }}
          onClick={() => setIsModalManageAuthorizeOpened(true)}
        >
          Administrar autorizaciones
        </Dropdown.Item>
      </DropdownButton>
    </>
  )
}

export default AuthorizeButton
