import React, { useContext, useEffect, useState } from 'react'
import { getAllViewablesFromDoc } from '../../utils/BubbleNodes'
import { allBubbleNodes, viewer } from './Viewer-helpers'
import DropdownButton from 'react-bootstrap/esm/DropdownButton'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { ViewerContext } from '../../context/ViewerContext'

const ViewsButton = ({}) => {
  const {
    areViewerDbIdsLoaded,
    setSelectedGuidViewCurrentModel,
    selectedGuidViewCurrentModel,
    handleRelaunchViewer,
    // relaunchViewer,
  } = useContext(ViewerContext)
  const [views, setViews] = useState([])

  const handleOnClick = (view) => {
    console.log('view selected', view)
    setSelectedGuidViewCurrentModel(view)
    handleRelaunchViewer()
  }

  useEffect(() => {
    if (areViewerDbIdsLoaded) {
      const allViewables = getAllViewablesFromDoc(allBubbleNodes)
      console.log('allViewables', allViewables)
      setViews(allViewables)
    }
  }, [areViewerDbIdsLoaded])

  if (!areViewerDbIdsLoaded) return

  return (
    <div
      style={{ position: 'absolute', top: '3rem', left: '3rem', zIndex: '500' }}
    >
      <DropdownButton
        id="dropdown-views-viewer"
        className="dropdown-views-viewer"
        style={{ display: 'flex!important', alignItems: 'center' }}
        title={
          <div style={{ display: 'flex' }}>
            <VisibilityIcon fontSize="large" />
            <p style={{ margin: 0, marginLeft: '.4rem' }}>
              {selectedGuidViewCurrentModel &&
              selectedGuidViewCurrentModel[0].data.name !== 'Default'
                ? selectedGuidViewCurrentModel[0].data.name
                : 'Vista por defecto'}
            </p>{' '}
          </div>
        }
      >
        {views?.map((view, index) => {
          return (
            <p
              onClick={() => handleOnClick(view)}
              style={{ cursor: 'pointer' }}
              className={`dropdown-views-viewer__item ${
                selectedGuidViewCurrentModel &&
                selectedGuidViewCurrentModel[0]?.data?.name ==
                  view[0]?.data?.name &&
                'dropdown-views-viewer__item--selected'
              }`}
            >
              {view[0]?.data?.name}
            </p>
          )
        })}
      </DropdownButton>
    </div>
  )
}

export default ViewsButton
