import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
const SearchProperties = ({valueInput, setValueInput}) => {
  return (
    <div
            className='search-properties-container'
          >
            <SearchIcon />

            <input
              className="search-properties-item"
              type="search"
              value={valueInput}
              onChange={(e) => setValueInput(e.target.value)}
              placeholder="Buscar propiedades por categoria o nombre"
            />
            {valueInput.length > 0 && (
              <CloseIcon
                style={{ cursor: 'pointer' }}
                onClick={() => setValueInput('')}
              />
            )}
          </div>
  )
}

export default SearchProperties