import React from 'react'
import ProductsList from './ProductsList'
import { useQuery } from '@tanstack/react-query'
import { getAllPrices } from '../../api/latamApi'

const ProductsContainer = () => {
  const { data, isLoading, isError } = useQuery(
    ['products'],
    () => getAllPrices(true),
    {
      suspense: true,
    }
  )

  if (isLoading || isError) return
  return (
    <div style={{ paddingTop: '5rem', paddingBottom: '5rem' }}>
      <ProductsList products={data} />
    </div>
  )
}
export default ProductsContainer
