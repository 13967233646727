import React, { useEffect, useState } from 'react'
import NameInput from './components/NameInput'
import ChipForm from './components/ChipForm'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/esm/Button'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { updateAuthorizationById } from '../../api/latamApi'
import { toast } from 'react-toastify'
const ModalEditAuthorization = ({ authorization, show, onHide }) => {
  const queryClient = useQueryClient()

  const [name, setName] = useState(authorization?.name || '')
  const [emails, setEmails] = useState(authorization?.guests || [])

  const mutationSuccess = () => {
    queryClient.invalidateQueries(['authorizations'])
    onHide()
    toast.success('Autorización actualizada.')
  }

  const mutationError = () => {
    onHide()
    toast.error('Ha ocurrido un error')
  }

  const editAuthorization = () => {
    const body = { name, guests: emails }
    editAuthorizationMutation.mutate({ id: authorization?._id, body })
  }

  const editAuthorizationMutation = useMutation({
    mutationFn: updateAuthorizationById,
    onSuccess: mutationSuccess,
    mutationError: mutationError,
  })

  const isNewData = () =>
    name.trim() != authorization?.name || emails !== authorization?.guests

  useEffect(() => {
    if (show) {
      setName(authorization?.name || '')
      setEmails(authorization?.guests || [])
    }
  }, [show])

  return (
    <Modal
      show={show}
      onHide={onHide}
      animation={false}
      fade={false}
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title>Actualizar {authorization?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <NameInput name={name} setName={setName} />
        <ChipForm emails={emails} setEmails={setEmails} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button disabled={!isNewData()} onClick={editAuthorization}>
          Actualizar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalEditAuthorization
