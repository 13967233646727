import React, { useEffect, useState, useRef, useContext, useMemo } from 'react'
import { initTree } from './Tree'
import LogoutIcon from '@mui/icons-material/Logout'
import GlobalSpinner from '../Common/GlobalSpinner'
import { UserContext } from '../../context/UserContext'
import LoginAutodesk from '../LoginAutodesk/LoginAutodesk'
import DoDisturbIcon from '@mui/icons-material/DoDisturb'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import axios from 'axios'
import { Button } from 'react-bootstrap'
import ButtonDocuActivateHub from '../Buttons/ButtonDocuActivateHub'
import { ViewerContext } from '../../context/ViewerContext'

function TooltipNotShowTree() {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Tienes que tener una suscripción activa para ver los modelos
    </Tooltip>
  )

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <DoDisturbIcon style={{ fill: 'red' }} />
    </OverlayTrigger>
  )
}

const AccTree = ({
  onIdSelected,
  versionName,
  visibleTree,
  versionArray,
  isCorrectUser,
}) => {
  const treeContainerRef = useRef(null)
  const { isLoadingAutodeskUser, userAutodesk } = useContext(UserContext)
  const [refVisible, setRefVisible] = useState(false)
  const { setSelectedGuidViewCurrentModel, handleRelaunchViewer } =
    useContext(ViewerContext)

  useEffect(() => {
    if (refVisible && userAutodesk) {
      console.log('USER AUTODESK', userAutodesk)
      console.log(axios.defaults.headers.common['Authorization'])
      const selector = treeContainerRef.current
      initTree(
        selector,
        (id) => onIdSelected(id),
        (name) => versionName(name),
        (versions) => versionArray(versions),
        setSelectedGuidViewCurrentModel,
        handleRelaunchViewer
      )
    }
  }, [refVisible, userAutodesk])

  if (isLoadingAutodeskUser) return <GlobalSpinner />
  if (!userAutodesk) return <LoginAutodesk />

  return (
    <div
      style={{
        minWidth: 'fit-content',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '2rem 1rem',
          gap: '1rem',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <LogoutIcon
          fontSize="large"
          onClick={() => window.location.replace('/api/auth/logout')}
          style={{ cursor: 'pointer', color: '#3A6079' }}
        />
        {/* <div style={{display: "flex", justifyContent: "space-between"}}> */}
        <span style={{ fontStyle: 'italic' }}>{userAutodesk?.name}</span>
        <ButtonDocuActivateHub />
      </div>
      {!visibleTree && <TooltipNotShowTree />}
      {/* </div> */}
      {visibleTree && isCorrectUser && (
        <div
          className="inspire-tree"
          ref={(el) => {
            treeContainerRef.current = el
            setRefVisible(!!el)
          }}
        />
      )}
    </div>
  )
}

export default AccTree
