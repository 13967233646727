import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/esm/Button'

const ModalSubscription = ({
  show,
  onHide,
  title,
  text,
  handleOnClick,
  textButton = 'Aceptar',
}) => {
  return (
    <Modal
      show={show}
      animation={false}
      fade={false}
      backdrop="static"
      centered
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ display: 'flex', justifyContent: 'center' }}>
        <p style={{ display: 'flex', margin: 0 }}>{text}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Volver
        </Button>
        <Button onClick={handleOnClick}>{textButton}</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalSubscription
