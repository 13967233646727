import React from 'react'
import NavbarLat from '../components/Navbar/Navbar'
import { Container } from 'react-bootstrap'
import Footer from '../components/Footer/Footer'
import LegalFooter from '../components/Footer/LegalFooter'

const RootLayout = ({ center = false, children }) => {
  const style = center
    ? {
        padding: '2rem',
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
    : { padding: '2rem', flex: '1' }
  return (
    <div
      style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
    >
      <NavbarLat />
      <Container fluid style={style}>
        {children}
      </Container>
      <Footer />
      <LegalFooter />
    </div>
  )
}

export default RootLayout
