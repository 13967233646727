import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'

const ChangeToPlanButton = ({ showModal, price }) => {
  return (
    <div>
      <Button
        style={{
          marginTop: '2rem',
          fontWeight: '600',
        }}
        onClick={showModal}
      >
        Cambiar de plan
      </Button>
    </div>
  )
}

export default ChangeToPlanButton
