import { useState } from 'react';
import TablePagination from '@mui/material/TablePagination';

const PaginationProperties = ({propertiesLength, page, setPage, rowsPerPage, setRowsPerPage}) => {
    // const [page, setPage] = useState();
    // const [rowsPerPage, setRowsPerPage] = useState(10);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

  return (
    <TablePagination
      component="div"
      className='table-pagination-container'
      count={propertiesLength}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage="Categorías por página"
      labelDisplayedRows={({ from, to, count }) => `${from} - ${to} de ${count}`}
       rowsPerPageOptions={[5, 10, 20, 25, 50]}
    />
  )
}

export default PaginationProperties