import React, { useContext, useEffect, useMemo, useState } from 'react'
import CustomCheckIcon from '../Icons/CustomCheckIcon'
import { UserContext } from '../../context/UserContext'
import Button from 'react-bootstrap/esm/Button'
import {
  freeTrialDaysUntilExpired,
  getDaysLeftToExpire,
  restarFechas,
} from '../../utils/restarFechas'
import SubscribeButton from './components/SubscribeButton'
import { createTrialSubscription } from '../../api/latamApi'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ModalConfirmChangeSubscription from '../ModalConfirmChangeSubscription/ModalConfirmChangeSubscription'
import CancelSubscriptionButton from './components/CancelSubscriptionButton'
import ChangeToPlanButton from './components/ChangeToPlanButton'
import ProductTitle from '../Products/components/ProductTitle'
import ProductPrice from '../Products/components/ProductPrice'
import ProductFeatures from '../Products/components/ProductFeatures'

const currency = {
  usd: {
    currency: '$',
    name: 'US',
  },
  eur: {
    currency: '€',
    name: '',
  },
}

const optionsPayment = {
  month: 'mes',
  year: 'año',
}

const ProductCardSetting = ({
  product,
  actualActive,
  noneActive,
  thereIsActive,
}) => {
  const { subscription, user, refetchSubscription } = useContext(UserContext)
  const [showModal, setShowModal] = useState(false)
  const navigate = useNavigate()
  const isFreeTrialPrice =
    product.id === process.env.REACT_APP_STRIPE_PRICEID_TRIAL
  const isSubscribedPrice = product.id === subscription?.plan?.id

  const handleFreeTrial = () => {
    createTrialSubscription()
      .then((res) => {
        navigate('/payment?success=true&freetrial=true&settings=true')
        refetchSubscription()
      })
      .catch((e) => {
        if (e?.response?.data?.show) return toast.error(e.response.data.message)
      })
  }

  const handleClickAumentarSubscripcion = () => {
    console.log('VAMOS A AUMENTAR LA SUBSCRIPCION EN: ', product)
  }

  useEffect(() => {
    console.log('CAMBIARAS? ', subscription)
  }, [subscription])

  return (
    <>
      <ModalConfirmChangeSubscription
        show={showModal}
        price={product}
        onHide={() => setShowModal(false)}
        text={`Vas a cambiar al plan ${product.recurring.interval}. ¿Estás seguro?`}
      />

      <div className="product-card no-hover">
        <div>
          <ProductTitle noMargin product={product} />
          <ProductPrice product={product} />
          <ProductFeatures product={product} />

          {/* BOTONES */}
          <div style={{ width: '100%' }}>
            {actualActive && (
              <CancelSubscriptionButton subscription={subscription} />
            )}
            {noneActive && <SubscribeButton price={product} />}
            {thereIsActive && !actualActive && (
              <ChangeToPlanButton showModal={() => setShowModal(true)} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductCardSetting
