import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const CustomTooltip = ({
  children,
  text,
  position = 'right',
  show = 250,
  hide = 200,
  isVisible = true,
}) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {text}
    </Tooltip>
  )
  if (!isVisible) return
  return (
    <OverlayTrigger
      placement={position}
      delay={{ show: show, hide: hide }}
      overlay={renderTooltip}
    >
      {children}
    </OverlayTrigger>
  )
}

export default CustomTooltip
