import { useQuery } from '@tanstack/react-query'
import InvoiceItemLine from './InvoiceItemLine'
import Table from 'react-bootstrap/Table'
import { getInvoices } from '../../../api/latamApi'
import GeneralError from '../../Error/GeneralError'
import { useEffect, useState } from 'react'
import PaginationFacturas from './PaginationFacturas'
import { CircularProgress } from '@mui/material'

const InvoicesSettings = () => {
  const limit = 10
  const [currentPage, setCurrentPage] = useState(1)
  const [loadingFacturas, setLoadingFacturas] = useState(false)
  const [startingAfterInvoice, setStartingAfterInvoice] = useState(null)
  const [endingBeforeInvoice, setEndingBeforeInvoice] = useState(null)
  const [lastInvoice, setLastInvoice] = useState(null)
  const [firstInvoice, setFirstInvoice] = useState(null)
  const { data, refetch, isFetching } = useQuery(
    ['invoicesUser'],
    () => getInvoices(limit, startingAfterInvoice, endingBeforeInvoice),
    {
      suspense: true,
    }
  )

  useEffect(() => {
    refetch()
  }, [currentPage])

  useEffect(() => {
    if (isFetching) {
      setLoadingFacturas(true)
    } else {
      console.log('FACTURAS: ', data)
      setLoadingFacturas(false)
    }
  }, [isFetching])

  useEffect(() => {
    console.log('LA DATAAAA: ', data)
    if (data?.data?.length > 0) {
      setLastInvoice(data.data[data.data.length - 1].id)
      setFirstInvoice(data.data[0].id)
    }
  }, [data])
  // const { invoices } = useInvoiceSettings()

  // if (isError || error) return <GeneralError>Error</GeneralError>
  if (data?.data?.data?.length == 0 || !data)
    return <p>No tienes ninguna factura</p>
  return loadingFacturas ? (
    <div className="spinner-container">
      <CircularProgress />
    </div>
  ) : (
    <div className="table-container">
      <Table size="sm" className="table-invoices">
        <thead>
          <tr>
            <th></th>
            <th>Nº Factura</th>
            <th>Fecha</th>
            <th>Cantidad</th>
            <th>Factura</th>
          </tr>
        </thead>
        <tbody>
          {data.data.map((invoice) => (
            <InvoiceItemLine invoice={invoice} />
          ))}
        </tbody>
      </Table>
      <PaginationFacturas
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        nextPage={data.moreInvoices}
        prevPage={currentPage > 1}
        lastInvoice={lastInvoice}
        firstInvoice={firstInvoice}
        setStartingAfterInvoice={setStartingAfterInvoice}
        setEndingBeforeInvoice={setEndingBeforeInvoice}
        setLoadingFacturas={() => setLoadingFacturas(true)}
      />
    </div>
  )
}

export default InvoicesSettings
