export const features = [
  {
    title: 'Descripción de BIMsualize',
    bgimg: './assets/img/b1.jpg',
    detail:
      'BIMsualize es una innovadora aplicación que revoluciona el proceso de gestión de datos en el ámbito de los modelos BIM alojados en Autodesk Construction Cloud. Esta poderosa herramienta permite gestionar de manera sencilla y eficiente los datos asociados a los modelos BIM.  Con BIMsualize, podrás realizar informes en Power BI que extraigan las métricas que necesites, obtener estados y análisis de avance, así como la extracción de indicadores clave de rendimiento (KPIs). Además, esta aplicación te brinda la capacidad de trabajar con funcionalidades avanzadas como la visualización en 4D y 5D, entre muchos otros casos de uso.  Nuestra aplicación está diseñada para simplificar el proceso de gestión de datos, permitiéndote ahorrar tiempo y esfuerzo al automatizar tareas complejas. Con una interfaz intuitiva y fácil de usar, BIMsualize te brinda acceso a información precisa y actualizada en tiempo real, lo que te ayudará a tomar decisiones informadas y optimizar tus proyectos de construcción.  Si estás buscando mejorar la gestión de datos en tus proyectos BIM y aprovechar al máximo las capacidades de Autodesk Construction Cloud, BIMsualize es la solución ideal. Visita nuestra página web y descubre cómo esta aplicación puede transformar tu forma de trabajar con modelos BIM y potenciar tus resultados.'
  },
  {
    title: 'Workflow único para la gestión de sus proyectos',
    bgimg: './assets/img/b2.jpg',
    vid: 'https://www.youtube.com/watch?v=OIdMkZyhx7E',
    detail:
      'Nuestra aplicación ofrece un flujo de trabajo único para la gestión de tus proyectos. Facilitamos el traspaso de información gráfica y no gráfica entre Autodesk Construction Cloud (ACC) y Power BI, permitiéndote seleccionar de manera detallada los datos que necesitas gestionar.  Con esta funcionalidad, podrás revisar tus procesos de manera eficiente, simplificar la gestión de datos y enviar información personalizada a cada uno de los roles BIM involucrados en el proceso. Ya no tendrás que lidiar con complicadas integraciones o transferencias masivas de datos. Nuestra aplicación se encarga de asegurar que la información relevante llegue a las manos adecuadas.  Además, al poder seleccionar y enviar datos personalizados, te brindamos la flexibilidad necesaria para adaptarte a las necesidades específicas de cada proyecto y de cada usuario. De esta manera, optimizamos la comunicación y la colaboración entre los diferentes actores del proceso BIM, mejorando la eficiencia y la toma de decisiones.  Además, al poder seleccionar y enviar datos personalizados, te brindamos la flexibilidad necesaria para adaptarte a las necesidades específicas de cada proyecto y de cada usuario. De esta manera, optimizamos la comunicación y la colaboración entre los diferentes actores del proceso BIM, mejorando la eficiencia y la toma de decisiones.',
  },
  {
    title: 'Multiples formatos y plantillas',
    bgimg: 'https://surgeon.atbim.io/assets/img/login.jpg',
    detail:
      'BIMsualize soporta los tipos de archivo BIM mas comunes (RVT, IFC, NWD, NWC ) y ofrece plantillas para Power BI ...',
    img: './assets/img/file_formats.png',
  },
  {
    title: 'Procesos sencillos …' + '\n' + 'resultados visibles',
    detail:
      'Nuestra aplicación simplifica el proceso de traspaso de datos en unos pocos pasos, lo que te permite obtener resultados impactantes en todas las etapas del ciclo de vida de tu proyecto. Nos adaptamos a las necesidades individuales de cada participante en el proceso, asegurando una gestión de datos efectiva y eficiente.  Con nuestro enfoque intuitivo y fácil de usar, te garantizamos que el traspaso de datos sea un proceso sin complicaciones. No importa si eres un principiante o un experto en la gestión BIM, nuestra aplicación te guiará paso a paso para obtener los resultados deseados.  Además, te ofrecemos acceso ilimitado a los modelos, lo que te brinda total libertad en la forma de gestionar la información. No importa cuántos modelos tengas o cuántos participantes estén involucrados, nuestra aplicación se adapta a tus necesidades y te permite gestionar la información de manera eficiente.  Descubre cómo nuestros procesos sencillos pueden generar resultados visibles y potenciar tus proyectos. Visita nuestra página web y aprovecha la libertad absoluta que ofrecemos en la gestión de la información. ¡Únete a nuestra plataforma y simplifica tu trabajo en el mundo BIM!',
    bgimg:
      'https://atbim.fra1.cdn.digitaloceanspaces.com/back_construction.jpg',
    card: [
      {
        title: '1. Proyecte en Revit',
        type: 1,
        icon: 'iconsminds-monitor-3',
        detail: 'Siga con su habitual flujo de trabajo.',
      },

      {
        title: '2. Extraiga su medición y presupuesto con su software habitual',
        type: 1,
        icon: 'iconsminds-link',
        detail:
          'No deje de trabajar con los programas de mediciones y presupuestos que ya conoce, integre un visualizador para mejorar el flujo de trabajo.',
      },
      {
        title: '3. Panel de mando de información de costes',
        type: 1,
        icon: 'iconsminds-statistic',
        detail:
          'El beneficio radica en la trazabilidad completa que tienes al trabajar contra el mismo archivo único, lo que ocurre en los distintos modelos y en el presupuesto, se actualiza en tiempo real.  ',
      },
    ],
  },
]
