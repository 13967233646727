import React from 'react'
import { Button } from 'react-bootstrap'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
const InvoiceItemLine = ({ invoice }) => {
  const isPaid = invoice.status == 'paid' ? true : false
  const style = !isPaid ? { color: 'red', backgroundColor: '#f7f7f7' } : null
  const date = new Date(invoice.created * 1000).toLocaleDateString('es-ES')
  return (
    <tr style={style}>
      <td>
        {isPaid ? (
          <CheckIcon style={{ fill: 'green', fontSize: '2rem' }} />
        ) : (
          <ClearIcon style={{ fill: 'red', fontSize: '2rem' }} />
        )}
      </td>
      <td>
        <p style={{ color: !isPaid && 'red' }}>{invoice.number}</p>
      </td>
      <td>{date}</td>
      {/* <p>{invoice.status}</p> */}
      <td>${(invoice.amount_due / 100).toFixed(2)}</td>
      <td>
        {isPaid && (
          <a href={invoice.invoice_pdf}>
            <Button>Descargar</Button>
          </a>
        )}
      </td>
    </tr>
  )
}

export default InvoiceItemLine
