import React, { useContext, useEffect } from 'react'
import { memo } from 'react'
import Helpers from './Viewer-helpers'
import './Viewer.css'
import ViewsButton from './ViewsButton'
import { ViewerContext } from '../../context/ViewerContext'

const Viewer = (props) => {
  console.log('props', props)
  const { selectedGuidViewCurrentModel, relaunchViewer } =
    useContext(ViewerContext)
  useEffect(() => {
    if (props.urn) {
      console.log('urn', window.btoa(props.urn))
      Helpers.launchViewer(
        'viewerDiv',
        window.btoa(props.urn),
        props.setAreViewerDbIdsLoaded,
        props.setAllPropertyNames,
        selectedGuidViewCurrentModel
      )
    }
  }, [relaunchViewer])
  return (
    <div
      className="forge-viewer"
      id="viewerDiv"
      style={{ position: 'relative' }}
    />
  )
}

export default memo(Viewer)
