import { motion } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'
import ProductCard from './ProductCard'
import ToggleRecurringPayButton from './ToggleRecurringPayButton'
import { useAuth0 } from '@auth0/auth0-react'

const ProductsList = ({ products }) => {
  const { user } = useAuth0()
  const [recurringPay, setRecurringPay] = useState('month')
  const [updateProducts, setUpdateProducts] = useState(products)
  console.log('products', products)

  useEffect(() => {
    if (!user) return
    setUpdateProducts(
      products.filter(
        (product) => product.id != process.env.REACT_APP_STRIPE_PRICEID_TRIAL
      )
    )
  }, [user])

  const handleRecurringPay = (value) => {
    setRecurringPay(value)
  }

  return (
    <div className="product-list-container">
      {/* <ToggleRecurringPayButton
        handleOnChange={handleRecurringPay}
        payValue={recurringPay}
      /> */}
      <h1 style={{ paddingBottom: '1rem' }}>Elige tu plan de BIMsualize PRO</h1>
      <motion.div
        className="products-cards-container"
        variants={variants}
        initial="hidden"
        animate="show"
      >
        {updateProducts.map((price) => (
          <motion.div key={price.id} variants={item}>
            <ProductCard key={price.id} product={price} />
          </motion.div>
        ))}
      </motion.div>
    </div>
  )
}

const variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.02,
    },
  },
}

const item = {
  hidden: {
    opacity: 0,
    x: -50,
  },
  show: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
}

export default ProductsList
