import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  addSettings,
  getAllSettingsByUser,
  getUserByAuth0Id,
} from '../../api/latamApi'
import { useAuth0 } from '@auth0/auth0-react'
import Helpers from '../Viewer/Viewer-helpers'
import { UserContext } from '../../context/UserContext'
import {
  manipulateDataToPaintSelectedProperties,
  manipulateDataToPaintSettingProperties,
} from '../../utils/handleData'

const ModalNameSettings = ({
  show,
  handleClose,
  selectedProperties,
  closeModalProperties,
}) => {
  const { user: userAuth0 } = useAuth0()
  const { user } = useContext(UserContext)
  const [name, setName] = useState('')
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [propertiesSelectedAgrouped, setPropertiesSelectedAgrouped] = useState(
    // vamos a agrupar las propiedades seleccionadas por categoría y meterlas en un objeto con sus cat y un array de name
    []
  )
  const manipulatedSetting =
    manipulateDataToPaintSelectedProperties(selectedProperties)

  const handleOnChange = (e) => {
    setName(e.target.value)
  }

  const handleOnSubmit = async (e) => {
    setLoadingSubmit(true)
    e.preventDefault()
    try {
      if (name.trim() === '') {
        setLoadingSubmit(false)
        return toast.error('El nombre no puede estar vacio', {
          id: 'toast-name-empty',
        })
      }
      // const {data: user} = await getUserByAuth0Id(userAuth0?.sub || "google-oauth2|109683755419701551153")
      const { data: settings } = await getAllSettingsByUser()

      // Comprobar si ya existe una configuración en BD con el mismo nombre
      const existsNameSetting = settings.find(
        ({ nombre }) => nombre.trim() == name.trim()
      )
      if (existsNameSetting) {
        setLoadingSubmit(false)
        return toast.error('Ya existe una configuración con este nombre')
      }

      await addSettings({
        nombre: name,
        usuario: user._id,
        extension: Helpers.getFileExtensionModel(),
        propiedades: selectedProperties.map(({ property }) => property),
      })

      toast.success('Configuración creada correctamente')
      handleCloseAndResetForm()
      closeModalProperties()
    } catch (e) {
      console.log(e)
      toast.error('Ha ocurrido un error al crear la configuración.')
    }
    setLoadingSubmit(false)
  }

  const handleCloseAndResetForm = () => {
    setName('')
    handleClose()
  }

  useEffect(() => {
    console.log('CATEGORIAS AGRUPADAS: ', propertiesSelectedAgrouped)
  }, [propertiesSelectedAgrouped])

  return (
    <Modal
      show={show}
      onHide={handleCloseAndResetForm}
      animation={false}
      fade={false}
      backdrop="static"
      centered
    >
      <Form onSubmit={handleOnSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Añadir nueva configuración</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup
            size="sm"
            className="mb-3"
            style={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: '2rem',
            }}
          >
            <Form.Label>Nombre de la configuración</Form.Label>
            <Form.Control
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              value={name}
              onChange={handleOnChange}
            />
          </InputGroup>
          {/* necesito agrupar en un array las propiedades seleccionadas por categoría y meterlas en un objeto con sus name */}
          <div style={{ maxHeight: '300px', overflow: 'auto' }}>
            {Object.keys(
              manipulateDataToPaintSelectedProperties(selectedProperties)
            ).map((category, index) => (
              <div key={index}>
                <p style={{ marginBottom: '.5rem', fontWeight: 700 }}>
                  {category}
                </p>
                <div style={{ marginLeft: '2rem' }}>
                  {manipulatedSetting[category].map((displayName, index) => (
                    <p style={{ marginBottom: '.5rem' }} key={index}>
                      {displayName}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={loadingSubmit}
            variant="secondary"
            onClick={handleCloseAndResetForm}
          >
            Cancelar
          </Button>
          <Button disabled={loadingSubmit} type="submit">
            Crear configuración
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ModalNameSettings
