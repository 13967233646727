import React from 'react'
import CustomCheckIcon from '../../Icons/CustomCheckIcon'

const arrayFeatures = (object, separator) => {
  return object.split(separator).map((feature) => feature.trim())
}

const ProductFeatures = ({ product }) => {
  const { product_info } = product
  console.log('product info', product_info)
  if (!product_info?.metadata?.features) return
  return (
    <div style={{ marginTop: '5rem', marginLeft: '2rem' }}>
      {/* <p>Esto incluye: </p> */}
      {arrayFeatures(product_info.metadata.features, ';').map((feature) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            marginTop: '1rem',
          }}
        >
          <CustomCheckIcon />
          <p style={{ margin: 0 }}>{feature}</p>
        </div>
      ))}
    </div>
  )
}

export default ProductFeatures
