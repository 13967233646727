import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/esm/Button'
import { CustomToggle } from '../../ModalManageSettings/SingleSettingItem'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteAuthorizationById } from '../../../api/latamApi'
import { toast } from 'react-toastify'
import ModalDeleteAuthorization from '../ModalDeleteAuthorization'
import { useState } from 'react'
import ModalEditAuthorization from '../ModalEditAuthorization'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

const AccordionAuthorize = ({ authorize, isGuest = false, children }) => {
  console.log('authorize', authorize)
  const [editAuthorizeSelected, setEditAuthorizeSelected] = useState(null)

  const [isModalDeleteOpened, setIsModalDeleteOpened] = useState(false)
  const queryClient = useQueryClient()

  const mutationSuccess = () => {
    queryClient.invalidateQueries(['authorizations'])
    setIsModalDeleteOpened(false)
    toast.success('Autorización eliminada.')
  }

  const mutationError = () => {
    setIsModalDeleteOpened(false)
    toast.error('Ha ocurrido un error')
  }

  const deleteAuthorization = () => {
    deleteAuthorizationMutation.mutate(authorize._id)
  }

  const deleteAuthorizationMutation = useMutation({
    mutationFn: deleteAuthorizationById,
    onSuccess: mutationSuccess,
    mutationError: mutationError,
  })

  const copyToClipboard = () => {
    const el = document.createElement('textarea')
    el.value = authorize.code
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    toast.success('Código copiado al portapapeles')
  }

  return (
    <>
      <ModalEditAuthorization
        show={editAuthorizeSelected}
        onHide={() => setEditAuthorizeSelected(null)}
        authorization={editAuthorizeSelected}
      />

      <ModalDeleteAuthorization
        show={isModalDeleteOpened}
        onHide={() => setIsModalDeleteOpened(false)}
        handleDelete={deleteAuthorization}
        name={authorize?.name}
      />
      <Accordion defaultActiveKey="0">
        <Card style={{ marginBottom: '.4rem' }}>
          <Card.Header
            style={{
              display: 'flex',
              gap: '.5rem',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div
              style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}
            >
              {!isGuest && <CustomToggle eventKey="1" />}
              <p style={{ margin: 0, fontWeight: 600 }}>{authorize.name}</p>
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center', gap: '.5rem' }}
            >
              <p style={{ margin: '0', fontSize: '1.2rem' }}>Código: </p>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
              >
                <p style={{ margin: '0' }}>{authorize.code}</p>
                <ContentCopyIcon
                  fontSize="medium"
                  onClick={copyToClipboard}
                  style={{ color: '#3A6079', cursor: 'pointer' }}
                />
              </div>

              {children}
            </div>
          </Card.Header>
          {!isGuest && (
            <Accordion.Collapse
              eventKey="1"
              style={{ padding: '1rem', fontSize: '12px' }}
            >
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '.75rem',
                  }}
                >
                  <p
                    style={{
                      fontSize: '1.4rem',
                      fontWeight: '600',
                      marginBottom: '.5rem',
                    }}
                  >
                    Invitados
                  </p>
                  {authorize.guests.length == 0 ? (
                    <p style={{ fontStyle: 'oblique', fontSize: '1.3rem' }}>
                      No hay usuarios autorizados
                    </p>
                  ) : (
                    authorize.guests.map((guest) => (
                      <p
                        style={{
                          marginBottom: '.5rem',
                          fontSize: '1.3rem',
                          marginLeft: '1rem',
                        }}
                        key={guest}
                      >
                        {guest}
                      </p>
                    ))
                  )}
                  {}
                </div>
                <div
                  style={{
                    marginTop: '1rem',
                    display: 'flex',
                    gap: '1rem',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    variant="danger"
                    onClick={() => setIsModalDeleteOpened(true)}
                  >
                    Eliminar
                  </Button>
                  <Button onClick={() => setEditAuthorizeSelected(authorize)}>
                    Editar
                  </Button>
                </div>
              </>
            </Accordion.Collapse>
          )}
        </Card>
      </Accordion>
    </>
  )
}

export default AccordionAuthorize
